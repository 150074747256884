<template>
  <v-main app>
    <Subtitle>다시듣기</Subtitle>

    <v-card flat>
      <v-tabs
          v-model="tab"
          fixed-tabs
      >
        <v-tab>설교</v-tab>
        <v-tab>예배실황</v-tab>
      </v-tabs>
    </v-card>




    <v-card tile height="100%">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card tile class="pt-2" elevation="2" color="primary" dark>
            <v-row dense no-gutters class="d-flex flex-row">
              <v-col
                  cols="6"
                  lg="4"
              >
                <v-text-field
                    prepend-icon="mdi-text-search"
                    v-model="conditionInput.keyword"
                    label="제목 검색"
                    class="mx-2"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="6"
                  lg="4"
              >
                <v-select
                    prepend-icon="mdi-book-cross"
                    v-model="conditionInput.bible"
                    :items="conditionList.bible"
                    label="성경선택"
                    class="mx-2"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-select
                    prepend-icon="mdi-book-cross"
                    v-model="conditionInput.worshipCategory"
                    :items="conditionList.worshipCategory"
                    label="예배종류"
                    class="mx-2"
                    small-chips
                    multiple
                ></v-select>
              </v-col>

            </v-row>
          </v-card>

          <v-row dense no-gutters class="d-flex flex-row">
            <v-col
                cols="12"
                sm="6"
                lg="4"
                xl="3"
                v-for="(item, index) in preachingListItems"
                :key="index"
            >
              <v-card
                  v-if="index < 30"
                  rounded
                  class="mx-3 my-2"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>


                <v-card-title v-text="item.title"></v-card-title>
                <v-card-subtitle>
                  {{ item.speaker }} | {{ item.worshipCategory }} | {{ item.date }}
                </v-card-subtitle>
                <v-card-text>

                  <div class="subtitle-1">
                    <v-chip dark>
                      {{ item.bible }} {{ item.paragraph }}
                    </v-chip>
                  </div>

                </v-card-text>

                <v-card-actions>
                  <v-btn
                      text
                      color="deep-purple lighten-2"
                      @click="viewMovie(item)"
                  >
                    말씀듣기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>



        <v-tab-item>
          <v-card tile class="pt-2" elevation="2" color="primary" dark>
            <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                :return-value.sync="dateOfIssue"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateOfIssue"
                    label="날짜"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="mx-5"
                ></v-text-field>
              </template>
              <v-date-picker
                  scrollable
                  locale="ko-KR"
                  v-model="dateOfIssue"
                  @change="$refs.datePicker.save(dateOfIssue)"
                  v-bind:events="eventFlags"
                  event-color="red lighten-2"
              >
              </v-date-picker>
            </v-menu>
          </v-card>


          <v-row dense no-gutters class="d-flex flex-column flex-sm-row">
            <v-col
                cols="12"
                sm="6"
                lg="4"
                xl="3"
                v-for="(item, index) in worshipListItem[dateOfIssue]"
                :key="index"
            >
              <v-card
                  v-if="index < 30"
                  rounded
                  class="mx-3 my-2"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>


                <v-card-title v-text="item.worship"></v-card-title>
                <v-card-subtitle>
                  {{ item.title }} | {{ item.speaker }}
                </v-card-subtitle>
                <v-card-text>

                  <div class="subtitle-1">
                    <v-chip dark>
                      {{ item.bible }} {{ item.paragraph }}
                    </v-chip>
                  </div>

                </v-card-text>

                <v-card-actions>
                  <v-btn
                      color="deep-purple lighten-2"
                      text
                      v-bind:href="item.url" target="_blank"
                  >
                    YOUTUBE에서 보기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>


        </v-tab-item>
      </v-tabs-items>
    </v-card>




    <Snakebar></Snakebar>

    <v-dialog
        v-if="movieDialog.activate"
        v-model="movieDialog.activate"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            dense
            color="primary"
        >
          <v-toolbar-title>{{ movieDialog.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="movieDialog.activate = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12">
              <v-responsive :aspect-ratio="16/9">
                <iframe id="player" width="100%" height="100%" v-bind:src="movieDialog.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </v-responsive>

              <v-btn block tile depressed color="primary" class="d-md-none mt-5" v-bind:href="movieDialog.mobileUrl" target="_blank">
                <v-icon small class="mr-2">
                  mdi-youtube-tv
                </v-icon>
                Youtube 앱에서 열기
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import Snakebar from "@/components/Snakebar"
// import store from '@/store/index.js'

export default {
  name: "Sermon",

  components: { Subtitle, Snakebar },

  mounted () {
    this.axios
        .get(process.env.VUE_APP_BACKEND_SERVER + '/moonhotv/PLEvf5F51_yeAPAEgXf7q-kpZUkIG0dYg5')
        .then((response) => {
          this.preachingListItemsTemp = response.data

          // 초기화
          for (let i = 0; i < response.data.length; i++) {
            // 성경 목록 임시저장
            this.conditionList.bibleTemp.push(response.data[i].bible)

            // 예배타입 목록
            if (this.conditionList.worshipCategory.indexOf(response.data[i].worshipCategory) === -1) {
              this.conditionList.worshipCategory.push(response.data[i].worshipCategory)
            }
          }

          // 성경 목록 재배열
          for (let i = 0; i < this.bookList.length; i++) {
            if (this.conditionList.bibleTemp.indexOf(this.bookList[i]) !== -1) {
              this.conditionList.bible.push(this.bookList[i])
            }
          }

          this.search()
        })

    this.axios
        .get(process.env.VUE_APP_BACKEND_SERVER + '/moonhotv/PLEvf5F51_yeBcPknyIJXKndsKvFKzjN9K',
            { params: { aggbyDate: 'yes' } })
        .then((response) => {
          this.worshipListItem = response.data

          this.eventFlags = Object.keys(this.worshipListItem).sort().reverse()
          this.dateOfIssue = this.eventFlags[0]
        })
  },

  data () {
    return {
      datePicker: false,
      dateOfIssue: this.$moment(new Date()).format('YYYY-MM-DD'),
      eventFlags: [],

      preachingListItemsTemp: [],
      preachingListItems: [],
      worshipListItem: [],

      tab: 0,
      items: [
        { tab: '말씀', content: 'Tab 1 Content' },
        { tab: '예배실황', content: 'Tab 2 Content' }
      ],

      conditionList: {
        bibleTemp: [],
        bible: ['전체', '구약', '신약'],
        worshipCategory: ['주일예배', '수요예배', '새벽기도회']
      },

      conditionInput: {
        keyword: '',
        bible: '전체',
        worshipCategory: ["주일예배"]
      },

      movieDialog: {
        activate: false
      },

      bookList : ["창세기","출애굽기","레위기","민수기","신명기","여호수아","사사기","룻기","사무엘상","사무엘하","열왕기상","열왕기하","역대상","역대하","에스라","느헤미야","에스더","욥기","시편","잠언","전도서","아가","이사야","예레미야","예레미야애가","에스겔","다니엘","호세아","요엘","아모스","오바댜","요나","미가","나훔","하박국","스바냐","학개","스가랴","말라기","마태복음","마가복음","누가복음","요한복음","사도행전","로마서","고린도전서","고린도후서","갈라디아서","에베소서","빌립보서","골로새서","데살로니가전서","데살로니가후서","디모데전서","디모데후서","디도서","빌레몬서","히브리서","야고보서","베드로전서","베드로후서","요한1서","요한2서","요한3서","유다서","요한계시록"]
    }
  },

  methods: {
    search () {
      this.preachingListItems = []

      for (let i = 0; i < this.preachingListItemsTemp.length; i++) {
        // 키워드 필터링
        if (this.conditionInput.keyword !== '') {
          if (this.preachingListItemsTemp[i].title.includes(this.conditionInput.keyword)) {
            console.log()
          } else {
            continue
          }
        }

        // 성경 필터링
        if (this.conditionInput.bible !== '전체') {
          console.log(this.conditionInput.bible)
          if (this.conditionInput.bible === this.preachingListItemsTemp[i].bibleType || this.conditionInput.bible === this.preachingListItemsTemp[i].bible) {
            console.log()
          } else {
            continue
          }
        }

        // 예배 필터링
        if (this.conditionInput.worshipCategory.includes(this.preachingListItemsTemp[i].worshipCategory)) {
          console.log()
        } else {
          continue
        }

        this.preachingListItems.push(this.preachingListItemsTemp[i])
      }

      // if (this.preachingListItems.length === 0) {
      //   store.commit('enableSnackbar', {
      //     text: "검색 결과가 없습니다",
      //     timeout: 2000
      //   })
      // }
    },

    viewMovie (item) {
      this.movieDialog.title = item.title
      this.movieDialog.url = item.url
      this.movieDialog.activate = true
      this.movieDialog.mobileUrl = item.url.replace('https://www.youtube.com/embed', 'https://youtu.be')
    }
  },

  watch: {
    conditionInput: {
      handler() {
        this.search()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>