<template>
  <v-main app>
    <Subtitle>오시는 길</Subtitle>

    <v-row no-gutters class="flex-column">
      <v-col
          width="100%"
          class="fill-height"
      >
        <div id="map" class="map"></div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-2 d-flex flex-column flex-md-row">

      <v-col cols="12" md="6">

        <v-card
            elevation="0"
            class="mx-2 mx-lg-5"
        >
          <v-card-title>
            차량 이용시
          </v-card-title>

          <v-alert
              dense
              color="primary"
              border="left"
              colored-border
              class="mx-5"
              elevation="2"
          >
            <v-card-text>
              문호교회는 경의중앙선 전철역 <v-chip small class="mx-1" color="primary" label>양수역</v-chip>에서 10분, 서울양양고속도로 <v-chip small class="mx-1" color="primary" label>서종IC</v-chip>에서 4분 거리에 있습니다.
            </v-card-text>

          </v-alert>

          <v-card-title>
            대중교통 이용시
          </v-card-title>

          <v-alert
              dense
              color="primary"
              border="left"
              colored-border
              class="mx-5"
              elevation="2"
          >농어촌버스
            <v-card-text>
              '문호1리.문호교회앞' 승강장에서 하차하시면 됩니다.
            </v-card-text>
            <v-alert>
              <v-chip small class="ma-1" color="primary" label>3-2</v-chip>
              <v-chip small class="ma-1" color="primary" label>3-20</v-chip>
              <v-chip small class="ma-1" color="primary" label>3-22</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-2</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-3</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-33</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-44</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-5</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-55</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-8</v-chip>
              <v-chip small class="ma-1" color="primary" label>8-9</v-chip>
              <v-chip small class="ma-1" color="primary" label>88-1</v-chip>
              <v-chip small class="ma-1" color="primary" label>88-2</v-chip>
              <v-chip small class="ma-1" color="primary" label>88-6</v-chip>
            </v-alert>

            지하철
            <v-card-text>
              경의중앙선 <v-chip small class="ma-1" color="primary" label>양수역</v-chip>에서 농어버스로 환승 후 <v-chip small class="mx-1" color="primary" label>문호1리.문호교회앞</v-chip>에서 하차하시면 됩니다.
            </v-card-text>

          </v-alert>

        </v-card>
      </v-col>


      <v-col cols="12" md="6">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >

          <v-card-title>
            주소
          </v-card-title>
          <v-card-text>
            (우)12502, 경기도 양평군 서종면 무내미길 73
          </v-card-text>

          <v-card-title>
            연락처
          </v-card-title>
          <v-card-text>
            <strong>교회</strong> 031-772-1693
            <strong>목양실</strong> 031-771-8487
            <strong>팩스</strong> 031-772-7537
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Map",

  components: { Subtitle },

  mounted () {
    window.kakao && window.kakao.maps
        ? this.initMap()
        : this.addKakaoMapScript();
  },

  methods: {
    addKakaoMapScript () {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=" + process.env.VUE_APP_KAKAO_JAVASCRIPT_KEY;
      document.head.appendChild(script);
    },

    initMap () {
      var container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(37.60470, 127.35027), //지도의 중심좌표.
        level: 4 //지도의 레벨(확대, 축소 정도)
      };

      var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

      // 마커가 표시될 위치입니다
      var markerPosition  = new kakao.maps.LatLng(37.60470, 127.35027);

      // 마커를 생성합니다
      var marker = new kakao.maps.Marker({
        position: markerPosition
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);
    }
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 400px;
}
</style>