import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import store from "./store"
import router from './router'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueSimpleMarkdown from 'vue-simple-markdown'
import './assets/vue-simple-markdown.css'
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";

Maps(Highcharts);
Vue.use(HighchartsVue);

moment.locale("ko");
Vue.use(VueMoment, { moment })
Vue.use(VueSimpleMarkdown)
Vue.config.productionTip = false

// axios
Vue.prototype.axios = axios

// firebase
let firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
        }, reject);
      }
  )};

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
