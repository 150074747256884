import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import Home from '@/views/Home.vue'
import Login from '@/components/Login.vue'

import History from '@/views/introduction/History.vue'
import People from '@/views/introduction/People.vue'
import Facility from '@/views/introduction/Facility.vue'
import Map from '@/views/introduction/Map.vue'
import Join from '@/views/introduction/Join.vue'
import Gallery from '@/views/introduction/Gallery.vue'
import Notice from '@/views/introduction/Notice.vue'
import Schedule from '@/views/introduction/Schedule.vue'

import Live from '@/views/moonhotv/Live.vue'
import Sermon from '@/views/moonhotv/Sermon.vue'
import MeetingTime from '@/views/moonhotv/MeetingTime.vue'
import Bulletin from '@/views/moonhotv/Bulletin.vue'
import Offertory from '@/views/moonhotv/Offertory.vue'

import GlobalMission from '@/views/mission/GlobalMission.vue'
import LocalMission from '@/views/mission/LocalMission.vue'

import Meeting from '@/views/community/Meeting.vue'

import ManagementNotice from '@/views/management/Notice.vue'
import ManagementBulletin from '@/views/management/Bulletin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/facility',
    name: 'Facility',
    component: Facility
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/join',
    name: 'Join',
    component: Join
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/bulletin',
    name: 'Bulletin',
    component: Bulletin
  },
  {
    path: '/bulletin/:yyyymmdd',
    name: 'Bulletin',
    component: Bulletin
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  {
    path: '/meetingtime',
    name: 'Meetingtime',
    component: MeetingTime
  },
  {
    path: '/offertory',
    name: 'Offertory',
    component: Offertory
  },
  {
    path: '/sermon',
    name: 'Sermon',
    component: Sermon
  },
  {
    path: '/meeting',
    name: 'Meeting',
    component: Meeting
  },
  {
    path: '/globalmission',
    name: 'GlobalMission',
    component: GlobalMission
  },
  {
    path: '/localmission',
    name: 'LocalMission',
    component: LocalMission
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/management/notice',
    name: 'ManagementNotice',
    component: ManagementNotice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/bulletin',
    name: 'ManagementBulletin',
    component: ManagementBulletin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cafe',
    beforeEnter() {
      window.open("https://cafe.daum.net/moonho.or.kr", '_blank')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    // 원하는 위치로 돌아가기
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()){
    next('login');
  } else {
    next();
  }
});

export default router
