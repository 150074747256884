<template>

  <v-app-bar app dark flat color="grey darken-3" height="80">
    <v-row no-gutters>
      <v-col class="col-2 col-md-10 d-flex">
        <v-row no-gutters class="d-md-none">
          <v-app-bar-nav-icon v-on:click="menuNavToggle"></v-app-bar-nav-icon>
        </v-row>

        <v-row no-gutters>
          <v-img
              src="@/assets/logo_dark.png"
              max-height="50"
              max-width="50"
              class="d-none d-md-inline-flex align-self-center ml-7 mr-7"
              transition="scale-transition"
              v-on:click="movePage('/')"
          />

          <div
              v-for="(memu, idx1) in propsmenu"
              :key=idx1
              class="d-none d-md-inline-block align-self-center"
          >
            <v-menu
                v-if="!memu.meta.requiresAuth || $store.state.moduleSession.session.user"
                open-on-hover
                offset-y
                nudge-left="5"
                transition="slide-y-transition"
                class="d-none d-md-inline-block align-self-center"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text
                       x-large
                       plain
                       v-bind="attrs"
                       v-on="on"
                       class="font-custom-menu"
                >
                  {{ memu.title }}
                </v-btn>
              </template>

              <v-list dense flat width="150">
                <v-list-item
                    v-for="(subItem, index) in memu.subtitles"
                    :key=index
                    link
                    class="ma-0 pa-0 d-flex justify-center"
                >
                  <v-btn block tile text plain right v-on:click="movePage(subItem.link)" v-bind:target="subItem.target" class="font-custom-menu">
                    {{ subItem.title }}
                    <v-icon right v-if="subItem.target" x-small>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-col>

      <v-col class="col-8 d-md-none" align-self="center">
        <v-row no-gutters class="f-flex flex-nowrap justify-space-around">
          <v-img
              src="@/assets/logo_dark.png"
              contain
              max-height="45"
              max-width="45"
              class="d-md-none align-self-end"
              transition="scale-transition"
              v-on:click="movePage('/')"
          />
        </v-row>
      </v-col>

      <v-col class="col-2 d-flex justify-end" align-self="center">
        <v-menu
            left
            offset-y
            open-on-hover
            nudge-top="10"
            transition="slide-y-transition"
            v-if="noticeItems.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                rounded
                fab
                small
                plain
                v-on="on"
                v-bind="attrs"
            >
              <v-badge
                  color="red"
                  dot
                  left
                  overlap
              >
                <v-icon medium>mdi-bell-ring-outline</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list min-width="250px" max-width="600px" dense two-line>
            <template
                v-for="(item, index) in noticeItems"
            >
              <v-list-item
                  :key="index"
                  ripple
                  @click="showNoticeItem(item)"
              >
                <v-list-item-content>
                  <span class="text-uppercase font-weight-regular caption">
                    {{ $moment(item.createTime).format('LL') }}
                  </span>
                  <div v-text="item.subject"></div>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                  v-if="index < noticeItems.length - 1"
                  :key="'divider' + index"
              ></v-divider>
            </template>
          </v-list>
        </v-menu>

        <v-btn rounded fab small plain class="mr-0 mr-md-4 mr-lg-8" v-on:click="settingNavToggle">
          <v-icon medium>mdi-cog-outline</v-icon>
        </v-btn>

      </v-col>
    </v-row>

    <v-dialog
        v-model="noticeDialog"
        scrollable
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          <v-toolbar-title>{{ noticeContent.subject }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                @click="noticeDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card
            class="pa-6"
            min-height="250"
        >
          <vue-simple-markdown :source="noticeContent.content"></vue-simple-markdown>
        </v-card>

        <v-card
            elevation="0"
            v-if="noticeContent.attachment"
        >
          <v-subheader>첨부파일</v-subheader>
          <v-card elevation="0" class="mx-5 mb-5">
            <v-chip
                v-for="(file, index) in noticeContent.attachment"
                v-bind:key="index"
                class="mr-2 mb-3 px-4"
                v-bind:href="backendServer + '/notice/file/' + noticeContent.num + '/' + file.sha256"
            >
              <v-avatar class="mr-2">
                <v-icon>mdi-file-download-outline</v-icon>
              </v-avatar>
              {{ file.filename }} ({{ file.size }})
            </v-chip>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import axios from "axios";

export default {
  name: "TopMenu.vue",

  props: ['propsmenu'],

  created () {
    axios.get(process.env.VUE_APP_BACKEND_SERVER + '/notice?limit=5')
        .then((response) => {
          this.noticeItems = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
  },

  data () {
    return {
      noticeItems: [],
      noticeDialog: false,
      noticeContent: {},
      backendServer: process.env.VUE_APP_BACKEND_SERVER
    }
  },

  methods: {
    movePage (url) {
      this.$router.push({ path: url}).catch(()=>{})
    },

    menuNavToggle () {
      this.$store.commit('menuNavToggle')
    },

    settingNavToggle () {
      this.$store.commit('settingNavToggle')
    },

    showNoticeItem (item) {
      this.noticeContent = {
        num: item.num,
        subject: item.subject,
        content: item.content,
        attachment: item.attachment
      }

      if (Object.keys(item.attachment).length == 0 ) {
        this.noticeContent.attachment = false
      }

      this.noticeDialog = true
    }
  }
}
</script>

<style scoped>
.font-custom-menu {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  letter-spacing : -0.06em;
}
</style>