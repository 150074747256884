<template>
  <v-main app>
    <Subtitle>문호교회의 발자취</Subtitle>

    <v-row>
      <v-col>
        <v-alert
            text
            color="info"
            class="mx-2"
        >
          <h3 class="text-h5">
            문호교회 119년
          </h3>
          <v-divider
              class="my-4 info"
              style="opacity: 0.22"
          ></v-divider>
          <div>1905년 가을 이 곳 문호리(무내미는 옛 지명)에 곽안련(Charles Allen Clark) 선교사와 양평 상심리교회의 차상진, 김영호 등이 와서 전도하여 이장수, 김영수, 유기량 등이 믿게 된 것이 문호교회 역사의 시작이다.</div>

          <v-divider
              class="my-2 info"
              style="opacity: 0"
          ></v-divider>

          <div>문호리(무내미)는 조선 시대에 매우 번화한 지역으로, 뱃길이 가장 중요한 교통수단이었던 그 시절 경성의 관문으로서 중요한 지리적 입지를 가지고 있었다. 무내미 나룻터를 중심으로 주막과 여관이 즐비하여 작은 서울로도 불렀다. 한편으로는 문호(抆;더러울 문, 湖;호수 호)라는 지명에서 알 수 있듯이 먹물을 갈아 물이 더러워질 정도로 공부하는 선비가 많았던 지역이기도 하다.</div>

          <v-divider
              class="my-2 info"
              style="opacity: 0"
          ></v-divider>

          <div>학당을 설립하고 독립운동에 참여하는 등 활발한 사회활동을 하여 양평 서종면 지역문화의 구심점 역할을 한 문호교회는, 1905년 2.5평방미터의 좁은 방에서부터 매년 크게 부흥하여 여섯칸의 예배당을 짓고 1911년에는 183명이 예배를 드렸다. 그러나 6.25전쟁으로 예배당이 전소되어, 1955년 10월에 무내미 강가의 돌을 온 성도와 동네 주민들이 협력하여서 소실된 예배당을 다시 지어 아름다운 한돌 성전으로 봉헌하였고, 1996년 본당건축, 2010년 교육관건축, 2015년 엘리베이터가 본당에 설치되었습니다. 현제 문호교회는 깊은 영성으로 세상을 아름답게 하는 교회로 지역사회와 함께 하고 있습니다.</div>

        </v-alert>
      </v-col>
    </v-row>

    <v-row no-gutters
           justify="center"
           class="ma-sm-3 ma-md-15">
      <v-timeline
          :reverse="reverse"
          :dense="$vuetify.breakpoint.smAndDown"
          class="mx-3 mx-md-10"
      >
        <v-timeline-item
            v-for="(contents, n) in history"
            :key="n"
            color="pink"
            small
            fill-dot
        >
          <v-row class="pt-1" v-if="$vuetify.breakpoint.smAndDown">
            <v-col>
              <strong>{{ contents.date }}</strong>
              <div class="caption" v-for="(item, index) in contents.title" v-bind:key="index">
                {{ item }}
              </div>
            </v-col>
          </v-row>

          <v-card flat rounded shaped elevation="1" v-else max-width="900" class="pb-5">
            <v-progress-linear background-color="blue-grey" value="0"></v-progress-linear>
            <v-card-title class="headline">
              {{ contents.date }}
            </v-card-title>
            <div v-for="(item, index) in contents.title" v-bind:key="index" class="mx-5">
              {{ item }}
            </div>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-row>
  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "History",

  components: { Subtitle },

  data () {
    return {
      reverse: false,
      history: [
        {
          'date': '1904. 02.',
          'title': ['곽안련 선교사가 문호리에 오셔서 노방전도']
        },
        {
          'date': '1905.',
          'title': ['이경춘 전도사가 선교부의 파송을 받고 문호리 김영수 씨 댁에서 창립예배를 드림']
        },
        {
          'date': '1906.',
          'title': ['6칸 예배당 건축함']
        },
        {
          'date': '1912. 03.',
          'title': ['정윤수 장로 장립']
        },
        {
          'date': '1930. 03.',
          'title': ['김경동 목사 부임(1931. 10. 사임)']
        },
        {
          'date': '1931.',
          'title': ['교회가 부흥함에 16칸 예배당 재건축']
        },
        {
          'date': '1935.',
          'title': ['윤상국 전도사 부임(1937. 10. 사임)']
        },
        {
          'date': '1944. 05.',
          'title': ['차보은 전도사 부임(1946. 03. 사임)']
        },
        {
          'date': '1946. 10.',
          'title': ['이원동 전도사 부임(1948. 01. 사임)']
        },
        {
          'date': '1948. 03.',
          'title': ['이은덕 전도사 부임(1950. 7. 사임)']
        },
        {
          'date': '1950. 06.',
          'title': ['6.25 동란으로 예배당 전소됨']
        },
        {
          'date': '1953. 03.',
          'title': ['임성록 장로 장립']
        },
        {
          'date': '1955. 04.',
          'title': ['정구봉 목사 부임(1957. 10. 사임)']
        },
        {
          'date': '1955. 10.',
          'title': ['6.25동란으로 소실된 예배당 복구(한돌성전)']
        },
        {
          'date': '1958. 01.',
          'title': ['김현수 목사 부임(1959. 10. 사임)']
        },
        {
          'date': '1959. 10.',
          'title': ['전대열 목사 부임(1962. 02. 사임)']
        },
        {
          'date': '1962. 03.',
          'title': ['권영학 전도사 부임(1963. 10. 사임)']
        },
        {
          'date': '1964. 03.',
          'title': ['고길환 전도사 부임(1965. 02. 사임)']
        },
        {
          'date': '1966. 11.',
          'title': ['송연금 전도사 부임(1968. 10. 사임)']
        },
        {
          'date': '1968. 12.',
          'title': ['김종희 목사 부임(1969. 06. 사임)']
        },
        {
          'date': '1969. 09.',
          'title': ['김거복 목사 부임(1970. 12. 사임)']
        },
        {
          'date': '1970. 12.',
          'title': ['이학원 전도사 부임(1973. 02. 사임)']
        },
        {
          'date': '1971. 11. 29',
          'title': [
            '임직식',
            '장로 임직: 김수현',
            '집사 임직: 신수남',
            '권사 임직: 김은애 김정애'
          ]
        },
        {
          'date': '1973. 03.',
          'title': ['유지욱 목사 부임(1973. 10. 사임)']
        },
        {
          'date': '1973. 11.',
          'title': ['송두규 목사 부임(1976. 12. 사임)']
        },
        {
          'date': '1977. 02.',
          'title': ['송연금 전도사 부임(1984. 04. 사임)']
        },
        {
          'date': '1982. 11. 11',
          'title': [
            '임직식',
            '장로 임직: 임종기',
            '집사 임직: 전순봉',
            '권사 임직: 김정순 박정임 정순희'
          ]
        },
        {
          'date': '1984. 10.',
          'title': ['문종규 목사 부임(1985. 12. 사임)']
        },
        {
          'date': '1986. 02. 04',
          'title': ['이진현 목사 부임(2008. 09. 30 사임)']
        },
        {
          'date': '1991. 11. 25',
          'title': [
            '은퇴 및 임직식',
            '장로 은퇴: 김수현',
            '장로 임직: 신용균',
            '집사 은퇴: 전순봉',
            '권사 은퇴: 김정애',
            '명예권사 추대: 방인순 임복순 김경윤'
          ]
        },
        {
          'date': '1993. 05. 24',
          'title': ['성전 기공 예배']
        },
        {
          'date': '1996. 09.',
          'title': [
            '새성전 입당 및 임직식',
            '장로 임직: 김원태',
            '집사 임직: 임정복 송영수',
            '권사 임직: 송혜자 이옥연 송연자 전임순 문순이'
          ]
        },
        {
          'date': '1999. 10.',
          'title': [
            '헌당 및 임직식',
            '집사 임직: 문창성 한명수 신송균 이광순 송형자 조선형 최윤선 전경숙 최영자 이범순 김덕순 백종선 송순자 최춘옥 공지미 김희순 최영숙 이점분'
          ]
        },
        {
          'date': '2002. 10. 03',
          'title': [
            '임직식',
            '원로장로 추대: 임종기',
            '장로 임직: 한명수 임정복',
            '집사 임직: 김중구 박기화 유명상 백승남 이호식 이환오 박정세 홍태화',
            '권사 임직: 이선희 전옥자 정남순 이남순 최은옥 한춘석 정의자 김옥순 박명종 김계순 채기례 안명임 이혜자 홍경희',
            '명예권사 추대: 이은남 노재화 이정용 김정희 이희환 윤필순 우완순'
          ]
        },
        {
          'date': '2003. 02. 09',
          'title': ['이훈구 부목사 부임(2007. 06. 30 사임)']
        },
        {
          'date': '2004. 08. 22',
          'title': ['강인덕, 이부영 선교사 파송']
        },
        {
          'date': '2005. 05. 05',
          'title': [
            '은퇴 및 임직식',
            '장로 은퇴: 김원태',
            '장로 임직: 유영환 박기화 이환오 신송균',
            '집사 임직: 박원모 이수호, 박현문 권관순 허원무',
            '권사 은퇴: 송혜자 김희순 최영순 이점분',
            '권사 임직: 민병주 양은순 김인숙 조경옥 이정수',
            '명예권사 추대: 조복순 조양자'
          ]
        },
        {
          'date': '2006. 10. 01',
          'title': ['100주년 기념 예배']
        },
        {
          'date': '2008. 11. 23',
          'title': ['함 택 목사 부임(2012. 06. 17 사임)']
        },
        {
          'date': '2009. 10. 31 ~ 11. 28',
          'title': ['아버지학교(구리 양평 14기) 유치']
        },
        {
          'date': '2009. 12. 27',
          'title': ['이미숙 교육목사 부임']
        },
        {
          'date': '2010. 04. 25',
          'title': [
            '위임 및 임직,은퇴식',
            '목사 위임: 함택',
            '장로 은퇴: 신용균',
            '집사 임직: 강동곤 계영환 남궁곤 남상식 박용찬 박준승 변상혁 이성종 황승택',
            '권사 은퇴: 이범순'
          ]
        },
        {
          'date': '2010. 06. 16',
          'title': ['문호리 785-5, 13, 14, 15번지(총 765평) 매입']
        },
        {
          'date': '2010. 07. 14',
          'title': ['한돌성전 부지 일부(790-15번지, 106㎡) 소유권 이전']
        },
        {
          'date': '2010. 09. 24',
          'title': ['교육관 착공(85평)']
        },
        {
          'date': '2010. 10. 17',
          'title': ['제 5회 양평성가 합창제 개최']
        },
        {
          'date': '2011. 02. 20',
          'title': ['교육관 입당 감사예배']
        },
        {
          'date': '2011. 04. 24',
          'title': ['장로 임직에배. 박용찬, 김중구, 이성종 장로 장립']
        },
        {
          'date': '2011. 12. 27',
          'title': ['한돌성전 부지 일부(789-4번지, 166㎡) 소유권 이전']
        },
        {
          'date': '2012. 07. 01',
          'title': ['전창근 목사 부임']
        },
        {
          'date': '2014. 02. 02',
          'title': ['이필희 부목사 부임(2020. 11. 22 사임, 갈운교회 담임으로 부임)']
        },
        {
          'date': '2014. 05. 11',
          'title': [
            '위임 및 은퇴식',
            '목사 위임: 전창근',
            '장로 은퇴: 한명수',
            '권사 은퇴: 송영자'
          ]
        },
        {
          'date': '2014. 08. 31',
          'title': ['교육관 내 나눔터 개관']
        },
        {
          'date': '2015. 08. 03 ~ 07',
          'title': ['청년공동체 베트남 단기선교']
        },
        {
          'date': '2015. 10. 04',
          'title': ['교회창립 110주년 및 승강기 봉헌예배']
        },
        {
          'date': '2016. 09. 04',
          'title': ['서승조 교육목사 부임']
        },
        {
          'date': '2016. 09. 12 ~ 19',
          'title': ['네팔 단기선교']
        },
        {
          'date': '2016. 10. 02',
          'title': ['본당 리모델링']
        },
        {
          'date': '2016. 11. 13',
          'title': [
            '은퇴 및 임직식',
            '장로 은퇴: 박기화 임정복',
            '장로 임직: 유명상',
            '집사 임직: 김영구 김형돈 박주근 신준철 오동훈 우한공 이영기 정진구 최형동',
            '권사 은퇴: 최윤선 송순자 주명숙',
            '권사 임직: 김분순 김영미 박선영 백옥순 신동숙 신지언 윤경은 윤순옥 이근주 이정희 이종화 이차남 지용자 최명희 최영섭'
          ]
        },
        {
          'date': '2016. 12. 25',
          'title': ['신동성 부목사 부임']
        },
        {
          'date': '2017. 07. 09 ~ 15',
          'title': ['청년공동체 몽골 단기 선교']
        },
        {
          'date': '2017. 12. 17',
          'title': ['김찬익 교육목사 부임']
        },
        {
          'date': '2018. 11. 18',
          'title': [
            '은퇴 및 임직식',
            '장로 임직: 박현문 오동훈',
            '집사 은퇴: 송기화 권익순',
            '집사 임직: 강천수 김동환 김웅변',
            '권사 은퇴: 문순이 김정희',
            '권사 임직: 고순연 김명숙 김혜성 박춘도 안정수 유영주 이규생 이재욱 최영인 최혜정',
            '명예권사 추대: 곽금선 여정동 유봉희 유영옥 유재정'
          ]
        },
        {
          'date': '2019. 02. 19',
          'title': ['문호열린사랑방교실 개설']
        },
        {
          'date': '2019. 03. 11 ~ 22',
          'title': ['그리스 터키 성지순례']
        },
        {
          'date': '2019. 06. 04',
          'title': ['문호 어린이 영어 작은도서관 개관']
        },
        {
          'date': '2019. 07. 08 ~ 12',
          'title': ['러시아 단기선교']
        },
        {
          'date': '2020. 01. 12',
          'title': ['선교주일(교회 15곳, 기관 25곳, 선교사 20곳 총 60곳 후원)']
        },
        {
          'date': '2020. 02. 16',
          'title': ['교회부지 구입(280평)']
        },
        {
          'date': '2020. 10. 11',
          'title': ['교회창립 115주년 기념 \'필라델피아 문호교회\' 건축']
        },
        {
          'date': '2021. 01. 03',
          'title': [
            '주일예배 3부로 확대',
            '기존 1부 8시, 2부 오전 11시, 찬양 오후 2시에서',
            '변경 1부: 8시, 2부: 9:30분, 3부: 11:30분으로 변경'
          ]
        },
        {
          'date': '2021. 04. 14',
          'title': [
            '교회1주차장 및 부지 아스콘포장공사'
          ]
        },
        {
          'date': '2021. 04. 23',
          'title': [
            '교회2주차장 바깥 펜스설치'
          ]
        },
        {
          'date': '2021. 11. 07',
          'title': [
            '은퇴 및 임직식',
            '장로 은퇴: 유영환 김중구',
            '장로 임직: 손창현',
            '집사 은퇴: 계영환 최형동 이영기 권병석 이치훈',
            '권사 은퇴: 공지미 최은옥 송유진 최영섭 이경수'
          ]
        },
        {
          'date': '2022. 01. 16',
          'title': [
            '선교주일(교회 17곳, 기관 26곳, 선교사 21곳 총 64곳 후원)'
          ]
        },
        {
          'date': '2022. 03. 27',
          'title': [
            '전쟁 중인 우크라이나 국민 및 강원지역 산불피해 지역 주민을 위한 구제헌금전달',
            '총 525만원 (서울노회)'
          ]
        },
        {
          'date': '2022. 04. 03',
          'title': [
            '문호나눔터(북카페 및 루디아 스토어) 재오픈'
          ]
        },
        {
          'date': '2022. 05. 29',
          'title': [
            '은퇴 및 임직식',
            '장로 은퇴: 이성종 조병찬',
            '장로 임직: 김동순 김형돈 박주근',
            '집사 은퇴: 남상식 김충경',
            '권사 은퇴: 백종선 채기례 김분순 이정희 서정순'
          ]
        },
        {
          'date': '2022. 04. 09',
          'title': [
            '영어성경 젭스 개강(매주 토요일)'
          ]
        },
        {
          'date': '2022. 09. 23',
          'title': [
            '금요여성 기도회(매주 금요일 오전 10:30분) 시작'
          ]
        },
        {
          'date': '2022. 10. 30',
          'title': [
            '교회 내 전체 냉온방, 전기 설비 교체'
          ]
        },
        {
          'date': '2022. 11. 06',
          'title': [
            '가을 구역 찬양 경연대회'
          ]
        },
        {
          'date': '2022. 11. 13. ~ 17',
          'title': [
            '필리핀 비낭오난 선교센터 10주기 기념 선교지 방문(김태현선교사)',
            '전창근 담임목사 및 신용균 은퇴장로 참석'
          ]
        },
        {
          'date': '2022. 12. 04',
          'title': [
            '김해연 목사 임직(서울노회, 10월 20일) 및 부목사 청빙',
            '(새가족담당 교육목사)'
          ]
        },
        {
          'date': '2022. 12. 22',
          'title': [
            '성탄절 사랑의 나눔',
            '관내 17가정 340만원 후원'
          ]
        },
        {
          'date': '2022. 12. 25',
          'title': [
            '노성광 부목사 부임'
          ]
        },
        {
          'date': '2023. 01. 15',
          'title': [
            '선교주일(교회 19곳, 기관 27곳, 선교사 22곳 총 68곳 후원)'
          ]
        },
        {
          'date': '2023. 02. 05',
          'title': [
            '교회 주일예배 차량운행 시작(차량 3대)'
          ]
        },
        {
          'date': '2023. 02. 12',
          'title': [
            '협력선교지를 위한 기도회 시작(매주 둘째주)'
          ]
        },
        {
          'date': '2023. 02. 26',
          'title': [
            '튀르키예-시리아 대지진 구제헌금 및 물품 전달(서울노회) 총 389만원'
          ]
        },
        {
          'date': '2023. 03. 05',
          'title': [
            '문호 코이노니아 클래스 개강'
          ]
        },
        {
          'date': '2023. 04. 23',
          'title': [
            '선교협력교회 안강평강교회 도서 기증(35박스)'
          ]
        },
        {
          'date': '2023. 05. 14',
          'title': [
            '문호공동체의 날(서종문화체육공원)'
          ]
        },
        {
          'date': '2023. 06. 04',
          'title': [
            '소예배실 바닥 난방 및 캐비넷 교체'
          ]
        },
        {
          'date': '2023. 06. 25',
          'title': [
            '주차장 부지 종교부지로의 전환'
          ]
        },
        {
          'date': '2023. 12. 15',
          'title': [
            '성탄절 사랑의 나눔',
            '관내 30가정 600만원 후원'
          ]
        },
        {
          'date': '2023. 12. 31',
          'title': [
            '은퇴식',
            '장로: 유명상',
            '권사: 송형자 양은순'
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.debug-border {
  border: 1px solid black;
}
</style>