<template>
  <v-app>
    <TopMenu
        v-bind:propsmenu="menu"></TopMenu>
    <NavMenu
        v-bind:propsmenu="menu"></NavMenu>
    <NavSetting></NavSetting>
    <router-view/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/TopMenu.vue'
import NavMenu from '@/components/NavMenu.vue'
import NavSetting from '@/components/NavSetting.vue'

export default {
  name: 'App',
  components: {
    TopMenu,
    NavMenu,
    NavSetting
  },
  data: () => ({
    menu: [
      {
        'title': '교회소개',
        'icon': 'mdi-church',
        'meta': {
          'requiresAuth': false
        },
        'subtitles': [
          {'title': '발자취', 'link': '/history'},
          {'title': '섬기는 사람들', 'link': '/people'},
          // {'title': '시설안내', 'link': '/facility'},
          {'title': '오시는 길', 'link': '/map'},
          {'title': '새가족 등록안내', 'link': '/join'},
          {'title': '문호 갤러리', 'link': '/gallery'},
          // {'title': '교회소식', 'link': '/notice'},
          {'title': '행사 및 일정', 'link': '/schedule'}
        ]
      },
      {
        'title': '예배와 말씀',
        'icon': 'mdi-television-play',
        'meta': {
          'requiresAuth': false
        },
        'subtitles': [
          {'title': '예배실황', 'link': '/live'},
          {'title': '다시듣기', 'link': '/sermon'},
          {'title': '예배 및 모임시간', 'link': '/meetingtime'},
          {'title': '주보', 'link': '/bulletin'},
          {'title': '온라인 헌금안내', 'link': '/offertory'}
        ]
      },
      {
        'title': '선교와 전도',
        'icon': 'mdi-cross-outline',
        'meta': {
          'requiresAuth': false
        },
        'subtitles': [
          {'title': '해외선교', 'link': '/globalmission'},
          {'title': '국내선교', 'link': '/localmission'}
        ]
      },
      {
        'title': '커뮤니티',
        'icon': 'mdi-account-multiple',
        'meta': {
          'requiresAuth': false
        },
        'subtitles': [
          {'title': '온라인 교제', 'link': '/meeting'},
          {'title': '다음카페', 'link': '/cafe', 'target': '_target'}
        ]
      },
      {
        'title': '사이트관리',
        'icon': 'mdi-gavel',
        'meta': {
          'requiresAuth': true
        },
        'subtitles': [
          {'title': '소식관리', 'link': '/management/notice'},
          {'title': '주보관리', 'link': '/management/bulletin'}
        ]
      }
    ]
  }),
  mounted() {
    const systemColor = localStorage.getItem("systemColor");
    const darkTheme = localStorage.getItem("darkTheme");

    if (systemColor && systemColor === "true") {
      if (window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (darkTheme) {
      if (darkTheme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
    }
  }
}
</script>


<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  letter-spacing : -0.04em;
}
</style>
