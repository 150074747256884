<template>
  <v-main app>
    <Subtitle>예배실황</Subtitle>

    <v-alert
        text
        dense
        border="left"
        type="error"
        v-if="noLive"
    >
      현재 진행중인 방송이 없습니다.
    </v-alert>

    <v-alert
        text
        border="left"
        type="error"
        dense
        v-if="noUpcoming"
    >
      예정된 방송이 없습니다.
    </v-alert>

    <v-alert
        text
        dense
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        v-show="live"
    >
      현재 진행중인 방송이 있습니다.
    </v-alert>

    <v-alert
        text
        dense
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        v-show="upcoming"
    >
      예정된 방송이 존재합니다.
      <v-alert
          dense
          text
          type="success"
          border="left"
          class="mt-2"
      >
        <strong>{{ castInfo.title }}</strong>
        <v-chip-group
            column
        >
          <v-chip>{{ $moment(castInfo.startTime).format('LLLL') }}</v-chip>

        </v-chip-group>
      </v-alert>
    </v-alert>

    <v-row no-gutters justify="start" class="d-flex flex-column flex-md-row">
      <v-col cols="12" md="9" v-if="!noUpcoming">
        <v-responsive :aspect-ratio="16/9">
          <iframe id="player" width="100%" height="100%" v-bind:src="castInfo.movieUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </v-responsive>

        <v-btn block tile depressed color="primary" class="d-md-none mt-5" v-bind:href="castInfo.mobileUrl" target="_blank">
          <v-icon small class="mr-2">
            mdi-youtube-tv
          </v-icon>
          Youtube 앱에서 열기
        </v-btn>
      </v-col>

      <v-col sm="0" md="3" v-if="!noUpcoming">
        <v-card
            class="mx-auto"
            width="100%"
            tile
        >
          <v-list
              flat
              dense
              v-for="(worshipType, i) in schedule"
              :key="i"
          >
            <v-subheader v-text="worshipType.title"></v-subheader>
            <v-divider class="mx-2"></v-divider>
            <v-list-item
                v-for="(item, i) in worshipType.timetable"
                :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.week + ' ' + item.title + ' ' + item.time"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Live",

  components: { Subtitle },

  created () {
    this.axios
        .get(process.env.VUE_APP_BACKEND_SERVER + '/moonhotv/live')
        .then((response) => {

          if (response.status == 204) {
            this.noLive = true
            this.noUpcoming = true
          } else {
            this.liveStatus = response['data']['liveStatus']
            this.castInfo.title = response['data']['title']
            this.castInfo.description = response['data']['description']
            this.castInfo.startTime = response['data']['startTime']
            this.castInfo.movieUrl = response['data']['movieUrl']
            this.castInfo.movieUrl = response['data']['movieUrl']
            this.castInfo.mobileUrl = response['data']['mobileUrl']

            if (this.liveStatus === "live") {
              this.live = true
            }
            else if (this.liveStatus === "upcoming") {
              this.noLive = true
              this.upcoming = true
            }
          }

        }).catch(() => {
      this.noLive = true
      this.noUpcoming = true
    })
  },

  data () {
    return {
      liveStatus: null,
      castInfo: {
        title: null,
        description: null,
        startTime: null,
        movieUrl: null,
        mobileUrl: null
      },
      live: false,
      upcoming: false,
      noLive: false,
      noUpcoming: false,
      schedule: [
        {
          title: '주일예배',
          timetable: [
            { title: '1부', week: '주일', time: '08:00', icon: 'mdi-clock' },
            { title: '2부', week: '주일', time: '09:30', icon: 'mdi-clock' },
            { title: '3부', week: '주일', time: '11:30', icon: 'mdi-clock' },
          ],
        },
        {
          title: '수요예배',
          timetable: [
            { title: '', week: '수', time: '19:30', icon: 'mdi-clock' }
          ],
        },
        {
          title: '새벽기도회',
          timetable: [
            { title: '', week: '월', time: '05:30', icon: 'mdi-clock' },
            { title: '', week: '화', time: '05:30', icon: 'mdi-clock' },
            { title: '', week: '수', time: '05:30', icon: 'mdi-clock' },
            { title: '', week: '목', time: '05:30', icon: 'mdi-clock' },
            { title: '', week: '금', time: '05:30', icon: 'mdi-clock' },
            { title: '', week: '토', time: '05:30', icon: 'mdi-clock' },
          ],
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>