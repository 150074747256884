<template>
  <v-navigation-drawer
      app
      v-model="$store.state.settingNav"
      temporary
      touchless
      right
  >
    <v-toolbar flat>
      <v-toolbar-title class="subtitle-1">
        Settings
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="settingNavClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>


    <v-list
        nav
        dense
    >
      <v-subheader v-if="deferredPrompt">웹앱 설치</v-subheader>

      <v-btn
          rounded
          small
          block
          v-if="deferredPrompt"
          v-on:click="install"
      >
        Install Web App
        <v-icon right plain>
          mdi-plus-circle
        </v-icon>
      </v-btn>
    </v-list>

    <v-list
        nav
        dense
    >
      <v-subheader>색상 테마</v-subheader>
      <v-list-item-group
          class="mx-auto"
          max-width="200"
          v-model="themeIndex"
          active-class="border"
          mandatory
      >
        <v-list-item
            v-for="(item, i) in themeItem"
            :key="i"
            v-on:click="themeChange(i)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-list
        nav
        dense
    >
      <v-subheader>사이트 관리</v-subheader>

      <v-btn
          rounded
          small
          block
          href="/login"
          v-if="!$store.state.moduleSession.session.user"
      >
        Login
        <v-icon right plain>
          mdi-login
        </v-icon>
      </v-btn>

      <v-btn
          v-else
          rounded
          small
          block
          v-on:click="logout"
      >
        Logout
        <v-icon right plain>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-list>

    <v-row v-if="$store.state.moduleSession.session.user">
      {{ $store.state.moduleSession.session.user.email }}
    </v-row>


  </v-navigation-drawer>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "NavSetting.vue",

  created () {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault()
      this.deferredPrompt = e;
    })

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null
    })
  },

  mounted () {
    const systemColor = localStorage.getItem("systemColor");
    const darkTheme = localStorage.getItem("darkTheme");

    if (systemColor && systemColor === "true") {
      this.themeIndex = 2
    } else if (darkTheme) {
      if (darkTheme === "true") {
        this.themeIndex = 1
      } else {
        this.themeIndex = 0
      }
    }
  },

  data () {
    return {
      deferredPrompt: null,
      themeItem: [
        {
          icon: 'mdi-weather-sunny',
          text: 'Light',
        },
        {
          icon: 'mdi-weather-night',
          text: 'Dark',
        },
        {
          icon: 'mdi-desktop-tower-monitor',
          text: 'System',
        }
      ],
      themeIndex: 2
    }
  },

  methods: {
    async install () {
      this.deferredPrompt.prompt()
    },
    settingNavClose () {
      this.$store.commit('settingNavToggle')
    },
    themeChange (value) {
      switch(value) {
        case 0:
          this.$vuetify.theme.dark = false;
          localStorage.setItem("systemColor", "false");
          localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString());
          break
        case 1:
          this.$vuetify.theme.dark = true;
          localStorage.setItem("systemColor", "false");
          localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString());
          break
        case 2:
          if (window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches) {
            this.$vuetify.theme.dark = true;
          } else {
            this.$vuetify.theme.dark = false;
          }
          localStorage.setItem("systemColor", "true");
          localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString());
          break
      }
    },
    logout () {
      firebase.auth().signOut().then(() => {
        this.$store.commit('deleteUser')
        this.$router.replace('login')
      }).catch((error) => {
        console.log("error" + error)
      });
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
  letter-spacing : -0.01em;
}
</style>