<template>
  <v-main app>
    <Subtitle>예배 및 모임시간</Subtitle>

    <!-- <v-alert
        text
        dense
        color="teal"
        type="info"
        border="left"
    >
      방역지침에 따라 현장예배(120명)와 실시간 영상예배를 병행하여 드립니다.
    </v-alert> -->

    <v-row no-gutters dense class="flex-column flex-sm-row">
      <v-col cols="12" md="6"
             v-for="(worshipType, i) in schedule"
             v-bind:key="i"
      >
        <v-card
            class="ma-3 ma-sm-4 ma-md-5"
            color="blue-grey lighten-5"
            tile
            flat
        >
          <v-list
              flat
          >
            <v-list-item-title v-text="worshipType.title" class="mx-3 mb-2"></v-list-item-title>

            <v-divider class="mx-2"></v-divider>
            <v-list-item
                v-for="(item, i) in worshipType.timetable"
                v-bind:key="i"
            >
              <v-chip
                  outlined
                  v-if="$vuetify.theme.dark"
                  class="my-2 mr-2"
                  color="secondary"
                  text-color="white"
              >
                {{ item.week }}
              </v-chip>
              <v-chip
                  v-else
                  outlined
                  class="my-2 mr-2"
                  color="secondary"
              >
                {{ item.week }}
              </v-chip>

              <v-list-item-content>
                <v-list-item-title v-text="item.title + ' ' + item.time">
                </v-list-item-title>
              </v-list-item-content>

              <v-chip
                  v-if="$vuetify.theme.dark"
                  class="ma-1"
                  color="blue darken-1"
              >
                {{ item.where }}
              </v-chip>
              <v-chip
                  v-if="!$vuetify.theme.dark"
                  class="ma-1"
                  color="blue lighten-1"
                  text-color="white"
              >
                {{ item.where }}
              </v-chip>

              <v-chip
                  v-if="item.live && $vuetify.theme.dark"
                  class="ma-1 d-none d-md-flex"
                  color="pink darken-1"
                  v-on:click="movePage('/live')"
              >
                <v-icon class="mr-1">
                  mdi-youtube-tv
                </v-icon>
                {{ item.live }}
              </v-chip>
              <v-chip
                  v-if="item.live && !$vuetify.theme.dark"
                  class="ma-1 d-none d-md-flex"
                  color="pink lighten-1"
                  text-color="white"
                  v-on:click="movePage('/live')"
              >
                <v-icon class="mr-1">
                  mdi-youtube-tv
                </v-icon>
                {{ item.live }}
              </v-chip>

              <v-chip
                  v-if="item.live && $vuetify.theme.dark"
                  class="ma-1 d-md-none"
                  color="pink darken-1"
                  v-on:click="movePage('/live')"
              >
                <v-icon>
                  mdi-youtube-tv
                </v-icon>
              </v-chip>
              <v-chip
                  v-if="item.live && !$vuetify.theme.dark"
                  class="ma-1 d-md-none"
                  color="pink lighten-1"
                  text-color="white"
                  v-on:click="movePage('/live')"
              >
                <v-icon>
                  mdi-youtube-tv
                </v-icon>
              </v-chip>

              <v-chip
                  v-if="item.onlinemeet && $vuetify.theme.dark"
                  class="ma-1 d-none d-md-flex"
                  color="blue-grey darken-1"
                  v-on:click="movePage('/meeting')"
              >
                <v-icon class="mr-1">
                  mdi-video-plus
                </v-icon>
                {{ item.onlinemeet }}
              </v-chip>
              <v-chip
                  v-if="item.onlinemeet && !$vuetify.theme.dark"
                  class="ma-1 d-none d-md-flex"
                  color="blue-grey lighten-1"
                  text-color="white"
                  v-on:click="movePage('/meeting')"
              >
                <v-icon class="mr-1">
                  mdi-video-plus
                </v-icon>
                  {{ item.onlinemeet }}
              </v-chip>

              <v-chip
                  v-if="item.onlinemeet && $vuetify.theme.dark"
                  class="ma-1 d-md-none"
                  color="blue-grey darken-1"
                  v-on:click="movePage('/meeting')"
              >
                <v-icon>
                  mdi-video-plus
                </v-icon>
              </v-chip>
              <v-chip
                  v-if="item.onlinemeet && !$vuetify.theme.dark"
                  class="ma-1 d-md-none"
                  color="blue-grey lighten-1"
                  text-color="white"
                  v-on:click="movePage('/meeting')"
              >
                <v-icon>
                  mdi-video-plus
                </v-icon>
              </v-chip>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "MeetingTime",

  components: { Subtitle },

  data () {
    return {
      schedule: [
        {
          title: '주일예배',
          timetable: [
            { title: '1부', week: '주일', time: '08:00', where: '대성전', live: 'Youtube', icon: 'mdi-clock', },
            { title: '2부', week: '주일', time: '09:30', where: '대성전', icon: 'mdi-clock', },
            { title: '3부', week: '주일', time: '11:30', where: '대성전', icon: 'mdi-clock', },
          ],
        },
        {
          title: '수요예배',
          timetable: [
            { title: '', week: '수', time: '19:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' }
          ],
        },
        {
          title: '새벽기도회',
          timetable: [
            { title: '', week: '월', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
            { title: '', week: '화', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
            { title: '', week: '수', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
            { title: '', week: '목', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
            { title: '', week: '금', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
            { title: '', week: '토', time: '05:30', where: '대성전', live: 'Youtube', icon: 'mdi-clock' },
          ],
        },
        {
          title: '교회학교 예배',
          timetable: [
            // { title: '유치유년부', week: '주일', time: '11:30', where: '교육관', onlinemeet: 'ZOOM', icon: 'mdi-clock' },
            { title: '유치유년부', week: '주일', time: '11:30', where: '교육관', icon: 'mdi-clock' },
            // { title: '아동부', week: '주일', time: '09:30', where: '소예배실', icon: 'mdi-clock' },
            { title: '아동부', week: '주일', time: '09:30', where: '소예배실', icon: 'mdi-clock' },
            // { title: '청소년부', week: '주일', time: '09:30', where: '한돌성전', onlinemeet: 'Google meet', icon: 'mdi-clock' },
            { title: '청소년부', week: '주일', time: '09:30', where: '한돌성전', icon: 'mdi-clock' },
            { title: '청년부', week: '주일', time: '13:30', where: '한돌성전', icon: 'mdi-clock' }
          ],
        }
      ]
    }
  },

  methods: {
    movePage (path) {
      this.$router.push({path: path})
    }
  }
}
</script>

<style scoped>

</style>