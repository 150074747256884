import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const initialState = () => {
    return {
        user: null,
        error: null
    }
}

const moduleSession = {
    state: () => ({
        session: initialState()
    }),
    mutations: {
        setUser(state, payload) {
            state.session.user = payload
        },
        setError(state, payload) {
            state.session.error = payload
        },
        deleteUser(state) {
            state.session.user = null
        }
    }
}

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ['moduleSession'],
            storage: window.sessionStorage
        })
    ],
    state: {
        menuNav: false,
        settingNav: false,
        snackbar: false,
        snackbarText: '',
        snackbarTimeout: 2000
    },
    mutations: {
        menuNavToggle(state) {
            state.menuNav = !state.menuNav
        },
        settingNavToggle(state) {
            state.settingNav = !state.settingNav
        },
        enableSnackbar(state, payload) {
            state.snackbar = true
            state.snackbarText = payload.text
            state.snackbarTimeout = payload.timeout
        },
        disableSnackbar(state) {
            state.snackbar = false
        }
    },
    modules: {
        moduleSession: moduleSession
    },
});
