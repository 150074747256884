<template>
  <v-main app>
    <Subtitle>온라인 교제</Subtitle>

    <v-alert
        text
        border="left"
        type="info"
        dense
    >
      비대면 온라인 교제(화상회의)을 연결을 지원합니다.
      <v-alert
          text
          light
          dense
          border="left"
          type="info"
          icon="mdi-video"
          class="mt-2"
          v-if="!this.$vuetify.theme.dark"
      >
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_zoom.png" contain></v-img>
          </v-avatar>
          ZOOM
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_googlemeet.png" contain></v-img>
          </v-avatar>
          Google Meet
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_webex.png" contain></v-img>
          </v-avatar>
          WebEx
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_tems.png" contain></v-img>
          </v-avatar>
          Teams
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_jitsi.png" contain></v-img>
          </v-avatar>
          Jitsi
        </v-chip>
        을 지원합니다.
      </v-alert>
      <v-alert
          text
          dark
          dense
          border="left"
          type="info"
          icon="mdi-video"
          class="mt-2"
          v-else
      >
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_zoom.png" contain></v-img>
          </v-avatar>
          ZOOM
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_googlemeet.png" contain></v-img>
          </v-avatar>
          Google Meet
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_webex.png" contain></v-img>
          </v-avatar>
          WebEx
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_tems.png" contain></v-img>
          </v-avatar>
          Teams
        </v-chip>
        <v-chip
            small
            class="mr-1">
          <v-avatar left>
            <v-img src="@/assets/conf_jitsi.png" contain></v-img>
          </v-avatar>
          Jitsi
        </v-chip>
        을 지원합니다.
      </v-alert>
    </v-alert>

    <v-alert
        text
        border="left"
        type="error"
        dense
        v-if="noUpcoming"
    >
      예정된 온라인 교제가 없습니다.
    </v-alert>

    <v-alert
        text
        dense
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        v-show="upcoming"
    >
      예정된 온라인 교제가 존재합니다.
      <v-card
          v-for="(event, idx) in events"
          :key="idx"
          class="mx-auto my-2"
          width="100%"
          rounded
          elevation="3"

      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">
              {{ event.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ $moment(event.start).format('LLLL') }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="50"
          >
            <v-img :src="event.conference.conferenceSolution.iconUri"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              color="primary"
              v-bind:href="event.uri"
              target="_blank"
          >
            참여하기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-alert>


    <v-row no-gutters justify="center">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
              flat
          >
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600px">
          <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
          >
            <template v-slot:day-body="{ date, week }">
              <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
              ></div>
            </template>
          </v-calendar>
          <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
          >
            <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
            >
              <v-toolbar
                  :color="selectedEvent.color"
                  dense
                  dark
                  flat
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    flat
                    rounded
                    color="secondary"
                    v-bind:href="selectedEvent.uri"
                    target="_blank"
                >
                  참여하기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Schedule",

  components: { Subtitle },

  created () {
    const events = []
    this.axios
        .get(process.env.VUE_APP_BACKEND_SERVER + '/calendar/meeting')
        .then((response) => {
          for (let i=0; i < response.data.length; i++) {
            events.push({
              name: response.data[i].title,
              details: response.data[i].details,
              start: new Date(response.data[i].start),
              end: new Date(response.data[i].end),
              location: response.data[i].location,
              color: this.colors[this.rnd(0, this.colors.length - 1)],
              timed: response.data[i].timed,
              uri: response.data[i].uri,
              conference: response.data[i].conference
            })
          }

          if (events.length > 0) {
            this.upcoming = true
          } else {
            this.noUpcoming = true
          }
        })

    this.events = events
  },

  mounted () {
    this.$refs.calendar.checkChange()
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },

  data () {
    return {
      liveStatus: null,
      castInfo: {
        title: null,
        description: null,
        startTime: null,
        movieUrl: null
      },
      upcoming: false,
      noUpcoming: false,

      value: '',
      ready: false,
      focus: '',
      type: '4day',
      typeToLabel: {
        week: 'Week',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
    }
  },

  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    }
  },

  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
  }
}
</script>

<style scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

&.first::before {
   content: '';
   position: absolute;
   background-color: #ea4335;
   width: 12px;
   height: 12px;
   border-radius: 50%;
   margin-top: -5px;
   margin-left: -6.5px;
 }
}
</style>