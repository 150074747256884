<template>
  <v-main app>
    <Subtitle>소식 관리</Subtitle>

    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          show-expand
          item-key="num"
          mobile-breakpoint="0"
          v-bind:headers="noticeHeaders"
          v-bind:items="noticeData"
          v-bind:single-expand="singleExpand"
          v-bind:expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialogCreate"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    rounded
                    class="mb-2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  소식등록
                </v-btn>
              </template>

              <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                  <v-btn
                      icon
                      dark
                      @click="dialogCreate = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>소식등록</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="save()"
                    >
                      SAVE
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-container fluid>
                  <v-row no-gutters class="mt-2 d-flex flex-column flex-md-row">
                    <v-col cols="12" md="6">
                      <v-alert
                          dense
                          outlined
                          type="error"
                          v-if="errorAlert"
                      >
                        {{ errorText }}
                      </v-alert>

                      <v-text-field
                          v-model="editedItem.subject"
                          filled
                          label="제목"
                          clearable
                      ></v-text-field>

                      <v-textarea
                          v-model="editedItem.content"
                          filled
                          label="본문 (Markdown Editor)"
                          auto-grow
                      ></v-textarea>

                      <v-file-input
                          chips
                          multiple
                          show-size
                          clearable
                          counter="2"
                          label="첨부파일"
                          v-model="editedItem.attachment"
                      ></v-file-input>
                    </v-col>

                    <v-col>
                      <v-card rounded outlined class="mb-10 mx-md-5 pb-5">
                        <v-subheader>미리보기</v-subheader>
                        <vue-simple-markdown :source="editedItem.content" class="mx-4"></vue-simple-markdown>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <v-dialog
              v-model="dialogEdit"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-btn
                    icon
                    dark
                    @click="close()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>소식수정</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                      dark
                      text
                      @click="update()"
                  >
                    UPDATE
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-container fluid>
                <v-row no-gutters class="mt-2 d-flex flex-column flex-md-row">
                  <v-col cols="12" md="6">
                    <v-alert
                        dense
                        outlined
                        type="error"
                        v-if="errorAlert"
                    >
                      {{ errorText }}
                    </v-alert>

                    <v-text-field
                        v-model="editedItem.subject"
                        filled
                        label="제목"
                        clearable
                    ></v-text-field>

                    <v-textarea
                        v-model="editedItem.content"
                        filled
                        label="본문 (Markdown Editor)"
                        auto-grow
                    ></v-textarea>

                    <v-divider></v-divider>

                    <v-list
                        width="95%"
                        class="ma-3"
                    >
                      <v-chip
                          v-for="(file, index) in editedItem.attachment"
                          v-bind:key="index"
                          class="mr-2 mb-3 px-4"
                          v-bind:href="backendServer + '/notice/file/' + editedItem.num + '/' + file.sha256"
                      >
                        <v-avatar class="mr-2">
                          <v-icon>mdi-file-download-outline</v-icon>
                        </v-avatar>
                        {{ file.filename }} ({{ file.size }})
                      </v-chip>
                    </v-list>
                  </v-col>

                  <v-col>
                    <v-card rounded outlined class="mb-10 mx-md-5 pb-5">
                      <v-subheader>미리보기</v-subheader>
                      <vue-simple-markdown :source="editedItem.content" class="mx-4"></vue-simple-markdown>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:item.createTime="{ item }">
          {{ $moment(item.createTime).format('YYYY-MM-DD') }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
              :colspan="headers.length"
              class="margin-color"
          >
            <v-card
                width="95%"
                class="ma-3"
                elevation="0"
            >
              <v-list-item-content>
                <vue-simple-markdown v-bind:source="item.content" class="mx-4"></vue-simple-markdown>
              </v-list-item-content>

              <v-divider></v-divider>

              <v-list
                  width="95%"
                  class="ma-3"
              >
                <v-chip
                    v-for="(file, index) in item.attachment"
                    v-bind:key="index"
                    class="mr-2 mb-3 px-4"
                    v-bind:href="backendServer + '/notice/file/' + item.num + '/' + file.sha256"
                >
                  <v-avatar class="mr-2">
                    <v-icon>mdi-file-download-outline</v-icon>
                  </v-avatar>
                  {{ file.filename }} ({{ file.size }})
                </v-chip>
              </v-list>
            </v-card>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="editItem(item)"
              class="mr-2"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item.num)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-row>

    <v-dialog
        v-model="dialogDelete"
        max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">소식을 삭제하시겠습니까?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="blue darken-1"
              @click="deleteItemConfirm"
          >
            삭제
          </v-btn>
          <v-btn
              text
              color="blue darken-1"
              @click="closeDelete"
          >
            취소
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snakebar></Snakebar>
  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import Snakebar from "@/components/Snakebar"
import axios from "axios"
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "ManagementNotice",

  components: {
    Subtitle,
    Snakebar
  },

  created () {
    this.initialize()
  },

  data () {
    return {
      expanded: [],
      singleExpand: true,
      editedItem: {
        num: null,
        subject: '',
        content: '',
        attachment: []
      },
      noticeHeaders: [
        { text: '작성일', value: 'createTime', align: 'start', width:'100'},
        { text: '제목', value: 'subject', sortable: false },
        { text: '수정/삭제', value: 'actions', sortable: false, width:'80' },
        { text: '보기', value: 'data-table-expand', width:'50' }
      ],
      noticeData: [],
      dialogCreate: false,
      dialogDelete: false,
      dialogEdit: false,
      errorAlert: false,
      errorText: '',
      backendServer: process.env.VUE_APP_BACKEND_SERVER
    }
  },

  methods: {
    initialize () {
      this.errorAlert = false

      this.editedItem = {
        num: null,
        subject: '',
        content: '',
        attachment: []
      }

      axios.get(process.env.VUE_APP_BACKEND_SERVER + '/notice')
          .then((response) => {
            this.noticeData = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })
    },

    editItem (item) {
      this.editedItem = {
        num: item.num,
        subject: item.subject,
        content: item.content,
        attachment: item.attachment
      }

      this.dialogEdit = true
    },

    deleteItem (num) {
      this.dialogDelete = true
      this.editedItem.num = num
    },

    deleteItemConfirm () {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        axios.create({
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        })
            .delete(process.env.VUE_APP_BACKEND_SERVER + '/notice/' + this.editedItem.num)
            .then((response) => {
              this.$store.commit('enableSnackbar', {
                text: response.data,
                timeout: 2000
              })
              this.initialize()
              this.closeDelete()
            })
            .catch((error) => {
              console.log(error.response)
            })
      }).catch((error) => {
        console.log(error)
      });
    },

    close () {
      this.dialogCreate = false
      this.dialogEdit = false
      this.initialize()
    },

    closeDelete () {
      this.dialogDelete = false
    },

    async save () {
      this.errorAlert = false

      let fd = new FormData()

      fd.append('subject', this.editedItem.subject)
      fd.append('content', this.editedItem.content)

      for (let i = 0; i < this.editedItem.attachment.length; i++) {
        fd.append('attachment', this.editedItem.attachment[i])
      }

      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        axios.create({
          headers: {
            'Authorization': 'Bearer ' + idToken,
            'Content-Type': 'multipart/form-data'
          }
        })
            .post(process.env.VUE_APP_BACKEND_SERVER + '/notice', fd)
            .then((response) => {
              this.$store.commit('enableSnackbar', {
                text: response.data,
                timeout: 2000
              })
              this.initialize()
              this.close()
            })
            .catch((error) => {
              if (error.response) {
                this.errorAlert = true
                this.errorText = error.response.data
              }
              else if (error.request) {
                this.errorAlert = true
                this.errorText = "첨부 파일은 최대 10MB를 넘을 수 없습니다."
              }

            })
      }).catch((error) => {
        console.log(error)
      });
    },

    async update () {
      this.errorAlert = false

      let fd = new FormData()

      fd.append('subject', this.editedItem.subject)
      fd.append('content', this.editedItem.content)

      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        axios.create({
          headers: {
            'Authorization': 'Bearer ' + idToken,
            'Content-Type': 'multipart/form-data'
          }
        })
            .put(process.env.VUE_APP_BACKEND_SERVER + '/notice' + this.editedItem.num, fd)
            .then((response) => {
              this.$store.commit('enableSnackbar', {
                text: response.data,
                timeout: 2000
              })
              this.initialize()
              this.close()
            })
            .catch((error) => {
              if (error.response) {
                this.errorAlert = true
                this.errorText = error.response.data
              }
              else if (error.request) {
                this.errorAlert = true
                this.errorText = "첨부 파일은 최대 10MB를 넘을 수 없습니다."
              }

            })
      }).catch((error) => {
        console.log(error)
      });
    }
  },

  watch: {
    dialogCreate (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>