<template>
  <v-snackbar
      v-model="$store.state.snackbar"
      :timeout="$store.state.snackbarTimeout"
  >
    {{ $store.state.snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="disableSnackbar()"
      >
        닫기
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snakebar",

  methods: {
    disableSnackbar () {
      this.$store.commit('disableSnackbar')
    }
  }
}
</script>

<style scoped>

</style>