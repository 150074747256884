<template>
  <v-main app>
    <Subtitle>헌금안내</Subtitle>

    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12" md="6">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            온라인 헌금계좌 안내
          </v-card-title>
          <v-alert
              dense
              elevation="2"
              icon="mdi-offer"
              color="blue-grey lighten-3"
              class="mx-5"
              v-if="!this.$vuetify.theme.dark"
          >
            예금주 : 대한예수교장로회문호교회
            <v-alert
                dense
                class="mt-3"
            >
              농협 355-0034-8194-63
            </v-alert>
          </v-alert>
          <v-alert
              v-else
              dense
              icon="mdi-offer"
              color="blue-grey darken-3"
              class="mx-5"
          >
            예금주 : 대한예수교장로회문호교회
            <v-alert
                dense
                class="mt-3"
            >
              농협 355-0034-8194-63
            </v-alert>
          </v-alert>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5"
        >
          <v-card-title>
            기재 시 유의사항
          </v-card-title>

          <v-card-text>
            인터넷(모바일) 또는 창구에서 온라인 헌금을 드리실 때 예시와 같이 <strong>‘받는통장에 표시할 내용’</strong>을 입력해 주시기 바랍니다.
          </v-card-text>

          <v-alert
              dense
              color="primary"
              border="left"
              colored-border
              class="mx-5"
              elevation="2"
          >기재사항
            <v-alert>
              <v-chip
                  class="mr-2"
                  color="primary"
                  label
              >
                성명
              </v-chip>
              <v-chip
                  class="mx-2"
                  color="primary"
                  label
              >
                헌금종류
              </v-chip>
            </v-alert>

            예시
            <v-text-field
                value="홍길동십일조"
                label="받는통장에 표시할 내용"
                filled
                class="mt-4"
            ></v-text-field>
            <v-text-field
                value="홍길동감사"
                label="받는통장에 표시할 내용"
                filled
            ></v-text-field>


            입금확인
            <v-card-text>
              손창현 장로(재정부장)에게 전화나 문자<v-chip small>010-3769-7931</v-chip>로 연락주세요.
            </v-card-text>

          </v-alert>

        </v-card>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Offertory",

  components: { Subtitle }
}
</script>

<style scoped>

</style>