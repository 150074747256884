<template>
  <v-row no-gutters>
    <v-card flat
            tile
            width="100%"
            color="grey lighten-4"
            v-if="!this.$vuetify.theme.dark"
    >
      <div class="mx-7 mx-md-10 my-2 my-md-3">
        <slot></slot>
      </div>
    </v-card>
    <v-card flat
            tile
            width="100%"
            color="grey darken-4"
            v-else
    >
      <div class="mx-7 mx-md-10 my-2 my-md-3">
        <slot></slot>
      </div>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "Subtitle"
}
</script>

<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: medium;
  font-weight: 500;
  letter-spacing : -0.04em;
}
</style>