<template>
  <v-main app>
    <v-container fill-height>
      <v-row no-gutters justify="center">
        <v-card
            flat
            min-width="350px"
        >
          <v-card-title>Login</v-card-title>

          <div class="ma-5">
            <v-text-field
                v-model="email"
                outlined
                label="이메일을 입력하세요"
                clearable
            ></v-text-field>
            <v-text-field
                v-model="password"
                v-on:keyup.enter="login"
                outlined
                label="패스워드를 입력하세요."
                type="password"
                clearable
            ></v-text-field>
            <v-btn
                color="primary"
                block
                v-on:click="login"
            >로그인</v-btn>
          </div>
        </v-card>
      </v-row>
    </v-container>
    <Snakebar></Snakebar>
  </v-main>
</template>

<script>
import Snakebar from "@/components/Snakebar"
import firebase from 'firebase/app'
import 'firebase/auth'
import store from '@/store/index.js'

export default {
  name: "Login",

  components: {
    Snakebar
  },

  data () {
    return {
      email: '',
      password: ''
    }
  },

  methods: {
    login () {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then((user) => {
                  store.commit('setUser', user)
                  store.commit('enableSnackbar', {
                    text: "로그인되었습니다.",
                    timeout: 2000
                  })
                  this.$router.push({path: '/'})
                }).catch((error) => {
                  store.commit('setError', error)
                  console.log("error: " + error.message)
                  store.commit('enableSnackbar', {
                    text: error.message,
                    timeout: 2000
                  })
                });
          })
          .catch(() => {});
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  letter-spacing : -0.06em;
}
</style>