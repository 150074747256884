<template>
  <v-main app>
    <Subtitle>주보</Subtitle>


    <v-row no-gutters>
      <v-col>
        <v-card tile class="pt-4 px-5" elevation="2" color="primary" dark>
          <v-menu
              ref="datePicker"
              v-model="datePicker"
              :close-on-content-click="false"
              :return-value.sync="dateOfIssue"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateOfIssue"
                  label="발행일"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                scrollable
                locale="ko-KR"
                event-color="red lighten-2"
                v-model="dateOfIssue"
                @change="$refs.datePicker.save(dateOfIssue)"
                v-bind:events="existBulletin"
            >
            </v-date-picker>
          </v-menu>
        </v-card>
      </v-col>
    </v-row>




    <v-row
        v-if="bulletinData[dateOfIssue]"
        no-gutters
        justify="center"
    >
      <v-col
          v-for="(file, index) in bulletinData[dateOfIssue].attachment"
          v-bind:key="index"
          cols="12"
          lg="6"
      >
        <v-card
            tile
            align="center"
            class="my-5"
            elevation="1"
        >
          <v-img
              v-bind:src="backendServer + '/static/bulletin/' + bulletinData[dateOfIssue].num + '/' + file.sha256"
              max-width="550"
              class="mx-2 rounded"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>


    <v-row
        justify="center"
        v-if="(!bulletinData[dateOfIssue] && (dateOfIssue != null))"
    >
      <v-alert
          dense
          width="100%"
          type="error"
          class="mt-5 px-7"
      >
        해당일에 발행된 주보가 없습니다.
      </v-alert>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import axios from "axios";

export default {
  name: "Bulletin",

  components: { Subtitle },

  created () {
    axios.get(process.env.VUE_APP_BACKEND_SERVER + '/bulletin?limit=24')
        .then((response) => {
          this.bulletinData = response.data
          this.existBulletin = Object.keys(this.bulletinData)

          if (this.$route.params && this.$route.params.yyyymmdd) {
            if (this.$route.params.yyyymmdd.length == 10) {
              this.dateOfIssue = this.$moment(this.$route.params.yyyymmdd, "YYYY-MM-DD").format('YYYY-MM-DD')
            }
            else if (this.$route.params.yyyymmdd.length == 8) {
              this.dateOfIssue = this.$moment(this.$route.params.yyyymmdd, "YYYYMMDD").format('YYYY-MM-DD')
            }
          } else {
            this.dateOfIssue = this.existBulletin[0]
          }
        })
        .catch((error) => {
          console.log(error)
        })
  },

  data () {
    return {
      datePicker: false,
      dateOfIssue: null,
      bulletinData: [],
      existBulletin: [],
      backendServer: process.env.VUE_APP_BACKEND_SERVER
    }
  }
}
</script>

<style scoped>

</style>