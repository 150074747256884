<template>
  <v-main app>
    <Subtitle>문호 갤러리</Subtitle>

    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row"
           v-for="(category, idx1) in images"
           :key="idx1"
    >
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            {{category.category}}
          </v-card-title>


          <v-row
              class="mb-5 mx-1"
          >
            <v-col
                v-for="(image, index) in category.photos"
                :key="index"
                class="d-flex child-flex"
                cols="6"
                md="3"
                lg="2"
            >
              <v-img
                  :src="image.thumbnail"
                  :lazy-src="image.thumbnail"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="viewImage(image)"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>








        </v-card>
      </v-col>
    </v-row>


    <v-dialog
        v-if="activate"
        v-model="activate"
    >
      <v-card dark>
        <v-row
            no-gutters
            class="fill-height"
            align="center"
            justify="center"
        >
          <v-img
              class="fill-height"
              :src="image.image"
              :lazy-src="image.image"
              @click="activate = false"
          >
            <v-toolbar
                dark
                dense
                color="primary"
                flat
            >
              <v-card-subtitle v-text="image.author"></v-card-subtitle>
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  dark
                  @click="activate = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-img>
        </v-row>
      </v-card>
    </v-dialog>


  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Gallery",

  components: { Subtitle },

  data () {
    return {
      activate: false,
      images: [
        {
          'category': '본당',
          'photos': [
            {
              'image': require('@/assets/gallery/01/0010.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0010.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0030.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0030.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0040.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0040.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0050.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0050.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0060.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0060.jpg'),
            },
            {
              'image': require('@/assets/gallery/01/0070.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0070.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0080.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0080.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0090.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0090.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0100.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0100.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/01/0110.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0110.jpg'),
            },
            {
              'image': require('@/assets/gallery/01/0120.png'),
              'thumbnail': require('@/assets/gallery/01/thumbnail/0120.jpg'),
              'author': '촬영 : 박형주 집사'
            }
          ]
        },
        {
          'category': '한돌성전',
          'photos': [
            {
              'image': require('@/assets/gallery/02/0010.png'),
              'thumbnail': require('@/assets/gallery/02/thumbnail/0010.jpg')
            },
            {
              'image': require('@/assets/gallery/02/0020.png'),
              'thumbnail': require('@/assets/gallery/02/thumbnail/0020.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/02/0030.png'),
              'thumbnail': require('@/assets/gallery/02/thumbnail/0030.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/02/0040.png'),
              'thumbnail': require('@/assets/gallery/02/thumbnail/0040.jpg'),
              'author': '촬영 : 박형주 집사'
            }
          ]
        },
        {
          'category': '교육관',
          'photos': [
            {
              'image': require('@/assets/gallery/03/0010.png'),
              'thumbnail': require('@/assets/gallery/03/thumbnail/0010.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/03/0020.png'),
              'thumbnail': require('@/assets/gallery/03/thumbnail/0020.jpg'),
              'author': '촬영 : 박형주 집사'
            },
          ]
        },
        {
          'category': '주변전경 및 기타',
          'photos': [
            {
              'image': require('@/assets/gallery/04/0075.jpg'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0075.jpg'),
              'author': '촬영 : 전창근 목사'
            },
            {
              'image': require('@/assets/gallery/04/0005.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0005.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0010.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0010.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0020.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0020.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0030.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0030.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0040.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0040.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0050.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0050.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0060.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0060.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0070.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0070.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0080.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0080.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0090.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0090.jpg')
            },
            {
              'image': require('@/assets/gallery/04/0100.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0100.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/04/0110.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0110.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/04/0120.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0120.jpg'),
              'author': '촬영 : 박형주 집사'
            },
            {
              'image': require('@/assets/gallery/04/0130.png'),
              'thumbnail': require('@/assets/gallery/04/thumbnail/0130.jpg'),
              'author': '촬영 : 박형주 집사'
            }
          ]
        }
      ]
    }
  },

  methods: {
    viewImage (image) {
      this.image = image
      this.activate = true
    }
  }
}
</script>

<style scoped>
img {
  width:100%;
  height:100%;
}
</style>