<template>
  <v-main app>
    <Subtitle>교회 소식</Subtitle>

    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          item-key="num"
          mobile-breakpoint="0"
          v-bind:headers="noticeHeaders"
          v-bind:items="noticeData"
          @click:row="showItem"
      >
        <template v-slot:top>
          <v-dialog
              scrollable
              v-model="dialogEdit"
          >
            <v-card>
              <v-toolbar
                  elevation="0"
                  color="primary"
                  dark
              >
                <v-toolbar-title>{{ editedItem.subject }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                      icon
                      @click="close()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card
                  class="pa-6"
                  min-height="250"
              >
                <vue-simple-markdown :source="editedItem.content">
                </vue-simple-markdown>
              </v-card>

              <v-card
                  elevation="0"
                  v-if="editedItem.attachment"
              >
                <v-subheader>첨부파일</v-subheader>
                <v-card elevation="0" class="mx-5 mb-5">
                  <v-chip
                      v-for="(file, index) in editedItem.attachment"
                      v-bind:key="index"
                      class="mr-2 mb-3 px-4"
                      v-bind:href="backendServer + '/notice/file/' + editedItem.num + '/' + file.sha256"
                  >
                    <v-avatar class="mr-2">
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-avatar>
                    {{ file.filename }} ({{ file.size }})
                  </v-chip>
                </v-card>
              </v-card>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:item.createTime="{ item }">
          {{ $moment(item.createTime).format('YYYY-MM-DD') }}
        </template>

        <template v-slot:item.subject="{ item }">
          {{ item.subject }}
        </template>

      </v-data-table>
    </v-row>

    <Snakebar></Snakebar>
  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import Snakebar from "@/components/Snakebar"
import axios from "axios"

export default {
  name: "Notice",

  components: {
    Subtitle,
    Snakebar
  },

  created () {
    this.initialize()
  },

  data () {
    return {
      editedItem: {
        num: null,
        subject: '',
        content: '',
        attachment: false
      },
      noticeHeaders: [
        { text: '작성일', value: 'createTime', align: 'start', width:'100' },
        { text: '제목', value: 'subject', sortable: false },
        { text: '작성자', value: 'userName', sortable: false, width:'100' }
      ],
      noticeData: [],
      dialogCreate: false,
      dialogDelete: false,
      dialogEdit: false,
      errorAlert: false,
      errorText: '',
      backendServer: process.env.VUE_APP_BACKEND_SERVER
    }
  },

  methods: {
    initialize () {
      this.errorAlert = false

      this.editedItem = {
        num: null,
        subject: '',
        content: '',
        attachment: false
      }

      axios.get(process.env.VUE_APP_BACKEND_SERVER + '/notice')
          .then((response) => {
            this.noticeData = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })
    },

    showItem (item) {

      this.editedItem = {
        num: item.num,
        subject: item.subject,
        content: item.content,
        attachment: item.attachment
      }

      if (Object.keys(item.attachment).length == 0 ) {
        this.editedItem.attachment = false
      }

      this.dialogEdit = true
    },

    deleteItem (num) {
      this.dialogDelete = true
      this.editedItem.num = num
    },

    close () {
      this.dialogCreate = false
      this.dialogEdit = false
      this.initialize()
    },

    closeDelete () {
      this.dialogDelete = false
    }
  },

  watch: {
    dialogCreate (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  }
}
</script>

<style scoped>

</style>