<template>
  <v-main app>
    <Subtitle>국내선교</Subtitle>

    <v-alert
        text
        dense
        color="info"
        icon="mdi-cross-outline"
        border="left"
    >
      교회 21곳과 기관 27곳을 후원하고 있습니다.
    </v-alert>

    <highcharts :constructorType="'mapChart'" class="hc" :options="localChartOptions" ref="chart"></highcharts>


    <v-dialog
        v-if="activate"
        v-model="activate"
        max-width="400"
    >
      <v-card
          max-width="400"
      >
        <v-card-title>{{ location.korean }}</v-card-title>

        <v-divider class="mx-4"></v-divider>

        <v-card-title>후원 교회</v-card-title>

        <v-card-text>

          <v-list>
            <v-list-item
                v-for="(item, index) in globalSupportData[location['idx']].list"
                :key="index"
            >
              <v-list-item-avatar>
                <v-icon
                    class="grey lighten-1"
                    dark
                >
                  mdi-folder
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.church }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.loc }} | {{ item.name }}</v-list-item-subtitle>
                {{ item.mission }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="activate = false"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>












    <v-row no-gutters justify="left" class="d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
        >
          <v-card-title
              class="mx-2"
          >
            교회 (21곳)
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          item-key="church"
          mobile-breakpoint="0"
          items-per-page="100"
          v-bind:sort-by="['church']"
          v-bind:headers="churchHeaders"
          v-bind:items="churchData"
      >
<!--        <template v-slot:expanded-item="{ headers, item }">-->
<!--          <td :colspan="headers.length">-->
<!--            {{ item.description }}-->
<!--          </td>-->
<!--        </template>-->
      </v-data-table>
    </v-row>














    <v-row no-gutters justify="left" class="d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mt-5"
        >
          <v-card-title
              class="mx-2"
          >
            기관 (27곳)
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          item-key="organization"
          mobile-breakpoint="0"
          items-per-page="100"
          v-bind:sort-by="['organization']"
          v-bind:headers="orgHeaders"
          v-bind:items="orgData"
      >
<!--        <template v-slot:expanded-item="{ headers, item }">-->
<!--          <td :colspan="headers.length">-->
<!--            {{ item.description }}-->
<!--          </td>-->
<!--        </template>-->
      </v-data-table>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import krMap from '@highcharts/map-collection/countries/kr/kr-all.geo.json'

export default {
  name: "LocalMission",

  components: { Subtitle },

  data () {
    return {
      singleExpand: true,
      orgHeaders: [
        { text: '기관', value: 'organization', align: 'start', width: 150 },
        { text: '대표자', value: 'representative', width: 110 },
        { text: '선교분류', value: 'type', width: 100, align: 'end' },
        //{ text: '사역', value: 'data-table-expand', align: 'end'}
      ],
      orgData: [
        {
          organization: '민족복음사관학교',
          representative: '박병길 목사',
          type: '남북통일선교',
          description: '북한선교 (탈북민 신학생 양성 및 후원)'
        },
        {
          organization: '목회데이터연구소',
          representative: '지용근 이사',
          type: '목회지원선교',
          description: '목회 통계자료 연구소'
        },
        // {
        //   organization: '가족사랑연구소',
        //   representative: '홍성원 소장',
        //   type: '장애인선교',
        //   description: '국내외 장애인 후원사역'
        // },
        {
          organization: '하늘소망선교회',
          representative: '이석우 목사',
          type: '후원선교',
          description: '독거노인 및 노숙인 전도사역'
        },
        {
          organization: '순교자기념선교회',
          representative: '예장통합총회',
          type: '후원선교',
          description: '유족복지 및 장학사업, 순교자기념사업'
        },
        {
          organization: '공군예비역기독연합회 BBR20',
          representative: '오원용 집사',
          type: '군선교',
          description: '격오지 공군 장병 문서사역'
        },
        {
          organization: '양평경목회',
          representative: '전창근 목사',
          type: '경찰선교',
          description: ''
        },
        {
          organization: '율로기아',
          representative: '차민석 목사',
          type: '후원선교',
          description: '청소년 캠프 등 문화사역 공동체'
        },
        {
          organization: '국제사랑재단',
          representative: '김승학 목사',
          type: '북한선교',
          description: '북,중,러 복음화'
        },
        // {
        //   organization: '한국점자도서관',
        //   representative: '육근해 관장',
        //   type: '장애인선교',
        //   description: '점자출판 및 국공립 점자도서관 설립지원'
        // },
        {
          organization: '현대종교',
          representative: '탁지원 소장',
          type: '이단사역',
          description: '이단, 사이비 연구 및 세미나 개최'
        },
        {
          organization: '소중한 사람들',
          representative: '유정옥 사모',
          type: '후원선교',
          description: '무료급식, 담요나누기, 거리전도'
        },
        {
          organization: '북한체제트라우마 상담치유센터',
          representative: '유혜란 목사',
          type: '북한선교',
          description: '탈북자들을 위한 상담, 회복'
        },
        {
          organization: '무지개뜨는언덕',
          representative: '임현식 목사',
          type: '교정선교',
          description: '성동구치소, 고봉소년원 교정사업'
        },
        {
          organization: '가락재영성원',
          representative: '정광일 목사',
          type: '후원선교',
          description: '영성 수련원'
        },
        {
          organization: '가족치유상담연구원',
          representative: '김영근 목사',
          type: '후원선교',
          description: '전인치유 세미나 및 상담'
        },
        {
          organization: '메신저',
          representative: '김춘호 대표',
          type: '후원선교',
          description: '필리핀, 베트남 거주 한국 혼혈계 어린이 후원'
        },
        {
          organization: '은퇴여교역자 안식관',
          representative: '주선애 목사',
          type: '후원선교',
          description: '은퇴여교역자 안식처'
        },
        {
          organization: '방파선교회',
          representative: '김영곤 목사',
          type: '후원선교',
          description: '선교사 파송 및 학교 및 센터 후원'
        },
        {
          organization: '대한성서공회',
          representative: '권의현 사장',
          type: '문서선교',
          description: '성경보급선교'
        },
        {
          organization: '아세아연합신학교',
          representative: '김영욱 총장',
          type: '학원선교',
          description: '신학대학교'
        },
        {
          organization: '장로회신학대학',
          representative: '임성빈 총장',
          type: '학원선교',
          description: '신학대학교'
        },{
          organization: '경신학원',
          representative: '이효종',
          type: '학원선교',
          description: ''
        },{
          organization: '기독공보',
          representative: '최기학 목사',
          type: '문서선교',
          description: ''
        },
        {
          organization: '극동방송',
          representative: '김장환 목사',
          type: '전파선교',
          description: ''
        },
        {
          organization: '랜선신학교',
          representative: '장민혁 전도사',
          type: '방송선교',
          description: ''
        },
        {
          organization: '서초노회 세계선교후원회',
          representative: '이군식 목사',
          type: '선교후원',
          description: ''
        },
        {
          organization: '미래목회와 말씀연구원',
          representative: '김지철 목사',
          type: '목회자교육',
          description: '미래목회를 위해 목회자들을 훈련하는 사역'
        },
        {
          organization: '명지성모병원',
          representative: '이기추 목사',
          type: '병원선교',
          description: '아픔 속에서 하나님을 영접하고 의지할 수 있게 하는 사역'
        },
      ],

      churchHeaders: [
        { text: '교회', value: 'church', align: 'start', width: 150 },
        { text: '교역자', value: 'representative', width: 110 },
        { text: '지역', value: 'location', width: 100, align: 'end' },
        // { text: '현황', value: 'data-table-expand', align: 'end'}
      ],
      churchData: [
        {
          church: '이작영락교회',
          representative: '강성기 목사',
          location: '인천 웅진',
          description: ''
        },
        {
          church: '안강평강교회',
          representative: '유영직 목사',
          location: '경북 경주',
          description: ''
        },
        // {
        //   church: '두물머리숲교회',
        //   representative: '박원덕 목사',
        //   location: '경기 양평',
        //   description: ''
        // },
        {
          church: '예수새민교회',
          representative: '안란희 전도사',
          location: '서울',
          description: '탈북민(새터민) 교회'
        },
        {
          church: '중산교회',
          representative: '박병상 목사',
          location: '경남 산청',
          description: ''
        },
        {
          church: '목왕교회',
          representative: '문광심 목사',
          location: '경기 양평',
          description: ''
        },
        {
          church: '진사교회',
          representative: '김한곤 목사',
          location: '경남 사천',
          description: ''
        },
        {
          church: '진흥교회',
          representative: '공만식 목사',
          location: '광주',
          description: '광주보훈병원 요양원, 예배 15명'
        },
        {
          church: '열매맺는교회',
          representative: '박두원 목사',
          location: '서울',
          description: '신월초 부근, 10여명, 침술 안마봉사'
        },
        {
          church: '복된은광교회',
          representative: '설은채 목사',
          location: '경기 시흥',
          description: '빈곤지역 위치 장년 9명'
        },
        {
          church: '사창교회',
          representative: '김영기 목사',
          location: '전북 고창',
          description: '농촌 미자립 교회'
        },
        {
          church: '삼광교회',
          representative: '이기철 목사',
          location: '대전',
          description: '농촌 미자립 교회'
        },
        {
          church: '삼성교회',
          representative: '윤덕영 목사',
          location: '경기 파주',
          description: '농촌 미자립 교회'
        },
        {
          church: '밝은빛새길교회',
          representative: '이병선 목사',
          location: '서울',
          description: '빈민, 소년원사역'
        },
        {
          church: '송림교회',
          representative: '최경욱 목사',
          location: '경기 여주',
          description: '농촌 미자립 교회'
        },
        {
          church: '대성교회',
          representative: '송정열 목사',
          location: '경남 서천',
          description: '농촌 미자립 교회'
        },
        {
          church: '원산교회',
          representative: '정성옥 목사',
          location: '충남 서천',
          description: '농촌 미자립 교회'
        },
        {
          church: '조은교회',
          representative: '염자룡 목사',
          location: '충북 충주',
          description: '농촌 미자립 교회'
        },
        {
          church: '언더우드선교회',
          representative: '담안유 목사',
          location: '서울',
          description: '이주민 사역교회'
        },
        {
          church: '더 사랑교회',
          representative: '구종모 목사',
          location: '서울',
          description: '서울노회 내 개척교회'
        },
        {
          church: '장성교회',
          representative: '정은주 목사',
          location: '서울',
          description: '서울역 쪽방촌에서 공원에서 200여명과 예배'
        },
        {
          church: '주님의교회',
          representative: '오해규 목사',
          location: '충북 충주',
          description: '후임목회자의 사례비 지원'
        }
      ],

      activate: false,
      location: '',

      globalSupportData: {
        gb: {
          list: [
            {
              church: '조은교회',
              name: '염자룡 목사',
              mission: '농촌 미자립 교회',
              loc: '충주'
            }
          ]
        },
        gn: {
          list: [
            {
              church: '원산교회',
              name: '정성옥 목사',
              mission: '농촌 미자립 교회',
              loc: '서천'
            }
          ]
        },
        so: {
          list: [
            {
              church: '대성교회',
              name: '손정열 목사',
              mission: '탈북민 교회',
              loc: '서울'
            },
            {
              church: '밝은빛새길교회',
              name: '이병선 목사',
              mission: '빈민, 소년원 사역',
              loc: '서울'
            },
            {
              church: '열매맺는교회',
              name: '박두원 목사',
              mission: '신월초 부근, 10여명, 침술 안마봉사',
              loc: '서울'
            },
            {
              church: '예수새민교회',
              name: '안란희 전도사',
              mission: '탈북민(새터민) 교회',
              loc: '서울'
            },
            {
              church: '언더우드선교회',
              name: '담안유 목사',
              mission: '이주민 사역교회',
              loc: '서울'
            }
          ]
        },
        kg: {
          list: [
            {
              church: '송림교회',
              name: '최경욱 목사',
              mission: '농촌 미자립 교회',
              loc: '여주'
            },
            {
              church: '복된은광교회',
              name: '설은채 목사',
              mission: '빈곤지역 위치, 장년 9명',
              loc: '시흥'
            },
            {
              church: '목왕교회',
              name: '문광심 목사',
              mission: '',
              loc: '양평'
            },
            // {
            //   church: '두물머리숲교회',
            //   name: '박원덕 목사',
            //   mission: '',
            //   loc: '양평'
            // },
            {
              church: '삼성교회',
              name: '윤덕영 목사',
              mission: '농촌 미자립 교회',
              loc: '파주'
            }
          ]
        },
        tj: {
          list: [
            {
              church: '삼광교회',
              name: '이기철 목사',
              mission: '농촌 미자립 교회',
              loc: '대전'
            }
          ]
        },
        in: {
          list: [
            {
              church: '이작영락교회',
              name: '강성기 목사',
              mission: '',
              loc: '웅진'
            }
          ]
        },
        cb: {
          list: [
            {
              church: '사창교회',
              name: '김영기 목사',
              mission: '농촌 미자립 교회',
              loc: '고창'
            }
          ]
        },
        kj: {
          list: [
            {
              church: '진흥교회',
              name: '공만식 목사',
              mission: '광주보훈병원 요양원, 예배 15명',
              loc: '광주'
            }
          ]
        },
        ng: {
          list: [
            {
              church: '안강평강교회',
              name: '유영직 목사',
              mission: '',
              loc: '경주'
            }
          ]
        },
        kn: {
          list: [
            {
              church: '진사교회',
              name: '김한곤 목사',
              mission: '정신장애인 사역',
              loc: '사천'
            },
            {
              church: '중산교회',
              name: '박병상 목사',
              mission: '농촌 미자립 교회',
              loc: '산청'
            }
          ]
        }
      },

      localChartOptions: {
        chart: {
          map: krMap,
          height: 500,
          backgroundColor: ''
        },
        title: {
          text: ''
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: 'spacingBox'
          },
          enableMouseWheelZoom: true,
          enableTouchZoom: true
        },
        colorAxis: {
          min: 200
        },

        enableMouseTracking: false,
        showInLegend: false,

        legend: {
          floating: false,
          enabled: false
        },

        tooltip: {
          useHTML: true,
          borderColor: '#aaa',
          headerFormat: '<b>{point.point.korean}</b><br>',
          pointFormat: ''
        },

        series: [{
          point: {
            events: {
              click: ({point}) => {
                this.dialogActivate(point)
              }
            }
          },
          states: {
            hover: {
              color: '#546e7a'
            }
          },
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            // format: '{point.name}',
            color: '#ffffff',
            formatter: function () {
              if (this.color === '#880e4f') {
                return this.point.korean;
              }
            }
          },
          allAreas: true,
          keys: ['hc-key', 'idx', 'color', 'korean'],
          data: [
            ['kr-kg', 'kg', '#880e4f', '경기도'],
            ['kr-cb', 'cb', '#880e4f', '전라북도'],
            ['kr-kn', 'kn', '#880e4f', '경상남도'],
            // ['kr-2685', 'jn', '#880e4f', '전라남도'],
            // ['kr-pu', 'pu', '#880e4f', '부산광역시'],
            ['kr-2688', 'ng', '#880e4f', '경상북도'],
            // ['kr-sj', 'sj', '#880e4f', '세종특별시'],
            ['kr-tj', 'tj', '#880e4f', '대전광역시'],
            // ['kr-ul', 'ul', '#880e4f', '울산광역시'],
            ['kr-in', 'in', '#880e4f', '인천광역시'],
            // ['kr-kw', 'kw', '#880e4f', '강원도'],
            ['kr-gn', 'gn', '#880e4f', '충청남도'],
            // ['kr-cj', 'cj', '#880e4f', '제주도'],
            ['kr-gb', 'gb', '#880e4f', '충청북도'],
            ['kr-so', 'so', '#880e4f', '서울특별시'],
            // ['kr-tg', 'tg', '#880e4f', '대구광역시'],
            ['kr-kj', 'kj', '#880e4f', '광주광역시']
          ]
        }]
      }
    }
  },

  methods: {
    dialogActivate (location) {
      this.activate = true
      this.location = location
    }
  },

  mounted () {
    if (this.$vuetify.theme.dark === true) {
      this.localChartOptions.chart.backgroundColor = "#111111"
    } else {
      this.localChartOptions.chart.backgroundColor = "#ffffff"
    }
  }
}
</script>

<style scoped>

</style>