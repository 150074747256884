<template>
  <v-main app>
    <Subtitle>해외선교</Subtitle>

    <v-alert
        text
        dense
        color="info"
        icon="mdi-cross-outline"
        border="left"
    >
      전 세계 22곳의 선교사를 후원하고 있습니다.
    </v-alert>

    <highcharts :constructorType="'mapChart'" class="hc" :options="globalChartOptions" ref="chart"></highcharts>


    <v-dialog
        v-if="activate"
        v-model="activate"
        max-width="400"
    >
      <v-card
          max-width="400"
      >

        <v-card-title>{{ location.name }}</v-card-title>

        <v-card-subtitle>
          {{ location.korean }}
        </v-card-subtitle>

        <v-divider class="mx-4"></v-divider>

        <v-card-title>후원 선교사</v-card-title>

        <v-card-text>

          <v-list>
            <v-list-item
                v-for="(item, index) in globalSupportData[location['hc-key']].list"
                :key="index"
            >
              <v-list-item-avatar tile>
                <v-card elevation="0" tile>
                  <v-img
                      :src="globalSupportData[location['hc-key']].flag"
                  ></v-img>
                </v-card>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="item.mission"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="activate = false"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>











    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          show-expand
          item-key="missionary"
          mobile-breakpoint="0"
          items-per-page="100"
          v-bind:sort-by="['missionary']"
          v-bind:headers="missionaryHeaders"
          v-bind:items="missionaryData"
          v-bind:single-expand="singleExpand"
      >
        <template v-slot:item.country="{ item }">
          <v-row dense no-gutters class="d-flex flex-row">
            <div>
              <v-img
                  width="30"
                  height="21"
                  :src="item.flag"
                  class="mr-2"
              ></v-img>
            </div>
            <div>
              {{ item.country }}
            </div>
          </v-row>

        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            {{ item.description }}
          </td>
        </template>
      </v-data-table>
    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import worldMap from '@highcharts/map-collection/custom/world.geo.json'

export default {
  name: "GlobalMission",

  components: { Subtitle },

  data () {
    return {
      singleExpand: true,
      missionaryHeaders: [
        { text: '선교사', value: 'missionary', align: 'start', width: 100 },
        { text: '선교지', value: 'country', width: 100 },
        { text: '사역', value: 'data-table-expand', align: 'end'}
      ],
      missionaryData: [
        {
          missionary : "C&P",
          country : 'M국',
          // flag: require('@/assets/flags/mu.jpg'),
          description : "현지 전도 및 구제사역"
        },
        {
          missionary : "박석출",
          country : '우간다',
          flag: require('@/assets/flags/ug.jpg'),
          description : "현지인 목회자 및 평신도 지도자 사역훈련"
        },
        {
          missionary : "최승근",
          country : '태국',
          flag: require('@/assets/flags/th.jpg'),
          description : "현지교회협력, 교회개척사역"
        },
        {
          missionary : "최윤승",
          country : '아르헨티나',
          flag: require('@/assets/flags/ar.jpg'),
          description : "한국 2-3세대 청년 크리스쳔 리더 양성"
        },
        {
          missionary : "한영국",
          country : '베트남',
          flag: require('@/assets/flags/vn.jpg'),
          description : "12년째 스포츠청소년선교"
        },
        {
          missionary : "토호시세마",
          country : '인도',
          flag: require('@/assets/flags/in.jpg'),
          description : "나갈랜드 인근국가 대상 신학교"
        },
        {
          missionary : "김학수",
          country : '네팔',
          flag: require('@/assets/flags/np.jpg'),
          description : "오지 및 빈민전도 및 청소년사역"
        },
        {
          missionary : "성문용",
          country : '네팔',
          flag: require('@/assets/flags/np.jpg'),
          description : "4년간 3교회 개척 총 200명 전도"
        },
        // {
        //   missionary : "김광진",
        //   country : '인도네시아',
        //   flag: require('@/assets/flags/id.jpg'),
        //   description : "신학교 현지인 대학과정으로 목회 (학비+생활비 월 5만원 4명 후원)"
        // },
        {
          missionary : "강승우",
          country : '파라과이',
          flag: require('@/assets/flags/py.jpg'),
          description : "연 3~4개 교회 개척 및 제자훈련"
        },
        {
          missionary : "이동관",
          country : '케냐',
          flag: require('@/assets/flags/ke.jpg'),
          description : "2006년부터 우물파기 사역 및 제자양성"
        },
        {
          missionary : "홍두표",
          country : '모잠비크',
          flag: require('@/assets/flags/mz.jpg'),
          description : "현지인 신학교, 노방전도 주일학교 운영"
        },
        {
          missionary : "고봉귀",
          country : '우간다',
          flag: require('@/assets/flags/ug.jpg'),
          description : "새마을 사업으로 현지 크리스챤 양성"
        },
        {
          missionary : "강정표",
          country : '캄보디아',
          flag: require('@/assets/flags/kh.jpg'),
          description : "프놈펜에서 주일학교 제자양육"
        },
        {
          missionary : "을지바트",
          country : '몽골',
          flag: require('@/assets/flags/mn.jpg'),
          description : "몽골 참사랑교회 개척, 청년공동체 사역"
        },
        // {
        //   missionary : "김덕규",
        //   country : '베트남',
        //   flag: require('@/assets/flags/vn.jpg'),
        //   description : "유치원 건축(6곳), 사랑의 집짓기(1700채)"
        // },
        {
          missionary : "하다윗",
          country : '튀르키예',
          flag: require('@/assets/flags/tr.jpg'),
          description : ""
        },
        {
          missionary : "최광순",
          country : '러시아',
          flag: require('@/assets/flags/ru.jpg'),
          description : ""
        },
        // {
        //   missionary : "이훈구",
        //   country : '미국',
        //   flag: require('@/assets/flags/us.jpg'),
        //   description : ""
        // },
        {
          missionary : "박광남",
          country : '필리핀',
          flag: require('@/assets/flags/ph.jpg'),
          description : ""
        },
        {
          missionary : "김태현",
          country : '필리핀',
          flag: require('@/assets/flags/ph.jpg'),
          description : ""
        },
        {
          missionary: "박종원",
          country: '말라위',
          flag: require('@/assets/flags/mw.png'),
          description: "아프리카 말라리아 예방을 통한 전도사역"
        },
        {
          missionary: "샤리프 알람",
          country: '파키스탄',
          flag: require('@/assets/flags/pk.png'),
          description: "현지 교회, 아동 및 여성 사역"
        },
        {
          missionary : "두관석",
          country : '인도네시아',
          flag: require('@/assets/flags/id.jpg'),
          description : "학교, 신학, 교회사역"
        },
        {
          missionary : "오원택",
          country : '대만',
          flag: require('@/assets/flags/tw.jpg'),
          description : "상담사역"
        },
      ],

      activate: false,
      location: '',

      globalSupportData: {
        ph: {
          flag: require('@/assets/flags/ph.jpg'),
          list: [
            {
              name: '김태현'
            },
            {
              name: '박광남'
            }
          ]
        },
        us: {
          flag: require('@/assets/flags/us.jpg'),
          list: [
            {
              name: '이훈구'
            }
          ]
        },
        ru: {
          flag: require('@/assets/flags/ru.jpg'),
          list: [
            {
              name: '최강순'
            }
          ]
        },
        tr: {
          flag: require('@/assets/flags/tr.jpg'),
          list: [
            {
              name: '하다윗'
            }
          ]
        },
        vn: {
          flag: require('@/assets/flags/vn.jpg'),
          list: [
            // {
            //   name: '김덕규',
            //   mission: '유치원 건축(6곳), 사랑의 집짓기(1700채)'
            // },
            {
              name: '한영국',
              mission: '스포츠 청소년 선교'
            }
          ]
        },
        mn: {
          flag: require('@/assets/flags/mn.jpg'),
          list: [
            {
              name: '을지바트',
              mission: '몽골 참사랑교회 개척, 청년 공동체 사역'
            }
          ]
        },
        kh: {
          flag: require('@/assets/flags/kh.jpg'),
          list: [
            {
              name: '강정표',
              mission: '프놈펜에서 주일학교 제자양육'
            }
          ]
        },
        ug: {
          flag: require('@/assets/flags/ug.jpg'),
          list: [
            {
              name: '고봉귀',
              mission: '새마을 사업으로 현지 크리스천 양성'
            },
            {
              name: '박석출',
              mission: '현지인 목회자 및 평신도 지도자 훈련 사역'
            }
          ]
        },
        mz: {
          flag: require('@/assets/flags/mz.jpg'),
          list: [
            {
              name: '홍두표',
              mission: '현지인 신학교, 노방전도 주일 학교 운영'
            }
          ]
        },
        ke: {
          flag: require('@/assets/flags/ke.jpg'),
          list: [
            {
              name: '이동관',
              mission: '우물파기 사역 및 제자 양성'
            }
          ]
        },
        py: {
          flag: require('@/assets/flags/py.jpg'),
          list: [
            {
              name: '강승우',
              mission: '교회 개척 및 제자 훈련'
            }
          ]
        },
        id: {
          flag: require('@/assets/flags/id.jpg'),
          list: [
            {
              name: '김광진',
              mission: '신학교 현지인 대학과정으로 목회'
            }
          ]
        },
        np: {
          flag: require('@/assets/flags/np.jpg'),
          list: [
            {
              name: '성문용',
              mission: '교회 개척 및 현지인 전도'
            },
            {
              name: '김학수',
              mission: '오지 및 빈민전도, 청소년사역'
            }
          ]
        },
        in: {
          flag: require('@/assets/flags/in.jpg'),
          list: [
            {
              name: '토호시세마',
              mission: '나갈랜드 인근국가 대상 신학교'
            }
          ]
        },
        ar: {
          flag: require('@/assets/flags/ar.jpg'),
          list: [
            {
              name: '최승윤',
              mission: '한국 2~3세대 청년 크리스천 리더 양성'
            }
          ]
        },
        th: {
          flag: require('@/assets/flags/th.jpg'),
          list: [
            {
              name: '최승근',
              mission: '현지 교회 협력, 교회 개척 사역'
            }
          ]
        },
        mu: {
          flag: require('@/assets/flags/mu.jpg'),
          list: [
            {
              name: '최인규',
              mission: '현지 전도 및 구제사역'
            }
          ]
        },
        // mw: {
        //   flag: require('@/assets/flags/mw.png'),
        //   list: [
        //     {
        //       name: '박종원',
        //       mission: '아프리카 말라리아 예방을 통한 전도사역'
        //     }
        //   ]
        // },
        // pk: {
        //   flag: require('@/assets/flags/pk.png'),
        //   list: [
        //     {
        //       name: '샤리프 알람',
        //       mission: '현지 교회, 아동 및 여성 사역'
        //     }
        //   ]
        // }
      },

      globalChartOptions: {
        chart: {
          map: worldMap,
          height: 500,
          backgroundColor: ''
        },
        title: {
          text: null
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: 'spacingBox'
          },
          enableMouseWheelZoom: true,
          enableTouchZoom: true
        },
        colorAxis: {
          min: 200
        },

        enableMouseTracking: false,
        showInLegend: false,

        legend: {
          floating: false,
          enabled: false
        },

        tooltip: {
          useHTML: true,
          borderColor: '#aaa',
          headerFormat: '<b>{point.point.name}</b><br>',
          //pointFormat: '<img style="width: 200px; height: 120px;">',
          pointFormat: ''
        },

        series: [{
          point: {
            events: {
              click: ({point}) => {
                this.dialogActivate(point)
              }
              // mouseOver: (point) => {
              //   this.activate = true
              //   console.log(point.name)
              // }
            }
          },
          states: {
            hover: {
              color: '#546e7a'
            }
          },
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            // format: '{point.name}',
            color: '#ffffff',
            formatter: function () {
              if (this.color === '#880e4f') {
                return this.point.korean;
              }
            }
          },
          allAreas: true,
          keys: ['hc-key', 'color', 'korean'],
          data: [
              // ['fo', 1],
              // ['um', 1],
              // ['us', '#880e4f', '미국'],
              // ['jp', 1],
              // ['sc', 1],
              ['in', '#880e4f', '인도'],
              // ['fr', 1],
              // ['fm', 1],
              // ['cn', 1],
              // ['pt', 1],
              // ['sw', 1],
              // ['sh', 1],
              // ['br', 1],
              // ['ki', 1],
              ['ph', '#880e4f', '필리핀'],
              // ['mx', 1],
              // ['es', 1],
              // ['bu', 1],
              // ['mv', 1],
              // ['sp', 1],
              // ['gb', 1],
              // ['gr', 1],
              // ['as', 1],
              // ['dk', 1],
              // ['gl', 1],
              // ['gu', 1],
              // ['mp', 1],
              // ['pr', 1],
              // ['vi', 1],
              // ['ca', 1],
              // ['st', 1],
              // ['cv', 1],
              // ['dm', 1],
              // ['nl', 1],
              // ['jm', 1],
              // ['ws', 1],
              // ['om', 1],
              // ['vc', 1],
              ['tr', '#880e4f', '튀르키예'],
              // ['bd', 1],
              // ['lc', 1],
              // ['nr', 1],
              // ['no', 1],
              // ['kn', 1],
              // ['bh', 1],
              // ['to', 1],
              // ['fi', 1],
              ['id', '#880e4f', '인도네시아'],
              // ['mu', '#880e4f', '모리셔스'],
              // ['se', 1],
              // ['tt', 1],
              // ['my', 1],
              // ['pa', 1],
              // ['pw', 1],
              // ['tv', 1],
              // ['mh', 1],
              // ['cl', 1],
              ['th', '#880e4f', '태국'],
              // ['gd', 1],
              // ['ee', 1],
              // ['ag', 1],
              ['tw', '#880e4f', '대만'],
              // ['bb', 1],
              // ['it', 1],
              // ['mt', 1],
              // ['vu', 1],
              // ['sg', 1],
              // ['cy', 1],
              // ['lk', 1],
              // ['km', 1],
              // ['fj', 1],
              ['ru', '#880e4f', '러시아'],
              // ['va', 1],
              // ['sm', 1],
              // ['kz', 1],
              // ['az', 1],
              // ['tj', 1],
              // ['ls', 1],
              // ['uz', 1],
              // ['ma', 1],
              // ['co', 1],
              // ['tl', 1],
              // ['tz', 1],
              ['ar', '#880e4f', '아르헨티나'],
              // ['sa', 1],
              ['pk', '#880e4f', '파키스탄'],
              // ['ye', 1],
              // ['ae', 1],
              ['ke', '#880e4f', '케냐'],
              // ['pe', 1],
              // ['do', 1],
              // ['ht', 1],
              // ['pg', 1],
              // ['ao', 1],
              ['kh', '#880e4f', '캄보디아'],
              ['vn', '#880e4f', '베트남'],
              ['mz', '#880e4f', '모잠비크'],
              // ['cr', 1],
              // ['bj', 1],
              // ['ng', 1],
              // ['ir', 1],
              // ['sv', 1],
              // ['sl', 1],
              // ['gw', 1],
              // ['hr', 1],
              // ['bz', 1],
              // ['za', 1],
              // ['cf', 1],
              // ['sd', 1],
              // ['cd', 1],
              // ['kw', 1],
              // ['de', 1],
              // ['be', 1],
              // ['ie', 1],
              // ['kp', 1],
              // ['kr', 1],
              // ['gy', 1],
              // ['hn', 1],
              // ['mm', 1],
              // ['ga', 1],
              // ['gq', 1],
              // ['ni', 1],
              // ['lv', 1],
              ['ug', '#880e4f', '우간다'],
              ['mw', '#880e4f', '말라위'],
              // ['am', 1],
              // ['sx', 1],
              // ['tm', 1],
              // ['zm', 1],
              // ['nc', 1],
              // ['mr', 1],
              // ['dz', 1],
              // ['lt', 1],
              // ['et', 1],
              // ['er', 1],
              // ['gh', 1],
              // ['si', 1],
              // ['gt', 1],
              // ['ba', 1],
              // ['jo', 1],
              // ['sy', 1],
              // ['mc', 1],
              // ['al', 1],
              // ['uy', 1],
              // ['cnm', 1],
              ['mn', '#880e4f', '몽골'],
              // ['rw', 1],
              // ['so', 1],
              // ['bo', 1],
              // ['cm', 1],
              // ['cg', 1],
              // ['eh', 1],
              // ['rs', 1],
              // ['me', 1],
              // ['tg', 1],
              // ['la', 1],
              // ['af', 1],
              // ['ua', 1],
              // ['sk', 1],
              // ['jk', 1],
              // ['bg', 1],
              // ['qa', 1],
              // ['li', 1],
              // ['at', 1],
              // ['sz', 1],
              // ['hu', 1],
              // ['ro', 1],
              // ['ne', 1],
              // ['lu', 1],
              // ['ad', 1],
              // ['ci', 1],
              // ['lr', 1],
              // ['bn', 1],
              // ['iq', 1],
              // ['ge', 1],
              // ['gm', 1],
              // ['ch', 1],
              // ['td', 1],
              // ['kv', 1],
              // ['lb', 1],
              // ['dj', 1],
              // ['bi', 1],
              // ['sr', 1],
              // ['il', 1],
              // ['ml', 1],
              // ['sn', 1],
              // ['gn', 1],
              // ['zw', 1],
              // ['pl', 1],
              // ['mk', 1],
              ['py', '#880e4f', '파라과이'],
              // ['by', 1],
              // ['cz', 1],
              // ['bf', 1],
              // ['na', 1],
              // ['ly', 1],
              // ['tn', 1],
              // ['bt', 1],
              // ['md', 1],
              // ['ss', 1],
              // ['bw', 1],
              // ['bs', 1],
              // ['nz', 1],
              // ['cu', 1],
              // ['ec', 1],
              // ['au', 1],
              // ['ve', 1],
              // ['sb', 1],
              // ['mg', 1],
              // ['is', 1],
              // ['eg', 1],
              // ['kg', 1],
              ['np', '#880e4f', '네팔']
          ]
        }]
      }
    }
  },

  methods: {
    dialogActivate (location) {
      this.activate = true
      this.location = location
    }
  },

  mounted () {
    if (this.$vuetify.theme.dark === true) {
      this.globalChartOptions.chart.backgroundColor = "#111111"
    } else {
      this.globalChartOptions.chart.backgroundColor = "#ffffff"
    }
  }
}
</script>

<style scoped>

</style>