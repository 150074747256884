<template>
  <v-main app>
    <Subtitle>주보 관리</Subtitle>

    <v-row
        class="flex-column"
        no-gutters
    >
      <v-data-table
          show-expand
          item-key="num"
          mobile-breakpoint="0"
          v-bind:headers="bulletinHeaders"
          v-bind:items="bulletinData"
          v-bind:single-expand="singleExpand"
          v-bind:expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
                max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    rounded
                    class="mb-2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  주보등록
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">주보등록</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                            ref="datePickerMenu"
                            v-model="datePickerMenu"
                            :close-on-content-click="false"
                            :return-value.sync="editedItem.dateOfIssue"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.dateOfIssue"
                                label="발행일"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="editedItem.dateOfIssue"
                              no-title
                              scrollable
                              @change="$refs.datePickerMenu.save(editedItem.dateOfIssue)"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            v-model="editedItem.volumeNum"
                            label="권"
                            :rules="rules.num"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            v-model="editedItem.issueNum"
                            label="호"
                            :rules="rules.num"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-file-input
                            chips
                            multiple
                            show-size
                            clearable
                            counter="10"
                            accept="image/jpeg, image/png"
                            label="주보 파일 (이미지만 첨부 가능)"
                            v-model="editedItem.attachment"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-alert
                            dense
                            outlined
                            type="error"
                            v-if="errorAlert"
                        >
                          {{ errorText }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                  >
                    취소
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                  >
                    저장
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogDelete"
                max-width="500px"
            >
              <v-card>
                <v-card-title class="headline">주보를 삭제하시겠습니까?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="blue darken-1"
                      @click="deleteItemConfirm"
                  >
                    삭제
                  </v-btn>
                  <v-btn
                      text
                      color="blue darken-1"
                      @click="closeDelete"
                  >
                    취소
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
              :colspan="headers.length"
              class="margin-color"
          >
            <v-carousel
                hide-delimiters
                hide-delimiter-background
                show-arrows-on-hover
                mandatory
                width="100%"
                height="100%"
            >
              <v-carousel-item
                  v-for="(file, index) in item.attachment"
                  v-bind:key="index"
                  eager
              >
                <v-card
                    align="center"
                    width="100%"
                    tile
                >
                  <v-img
                      v-bind:src="backendServer + '/static/bulletin/' + item.num + '/' + file.sha256"
                      v-bind:width="bulletinWidth"
                      max-width="700"
                      eager
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="deleteItem(item.num)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-row>

    <Snakebar></Snakebar>
  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"
import Snakebar from "@/components/Snakebar"
import axios from "axios"
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "ManagementBulletin",

  components: {
    Subtitle,
    Snakebar
  },

  created () {
    this.initialize()
  },

  data () {
    return {
      rules: {
        num: [
          v => Number.isInteger(Number(v)) || "숫자만 입력하세요.",
        ]
      },
      expanded: [],
      singleExpand: true,
      editedItem: {
        num: null,
        dateOfIssue: new Date().toISOString().substr(0, 10),
        volumeNum: '',
        issueNum: '',
        attachment: []
      },
      bulletinHeaders: [
        { text: '발행일', value: 'dateOfIssue', align: 'start' },
        { text: '권', value: 'volumeNum', sortable: false },
        { text: '호', value: 'issueNum', sortable: false },
        { text: '삭제', value: 'actions', sortable: false },
        { text: '주보', value: 'data-table-expand', width: 70}
      ],
      bulletinData: [],
      dialog: false,
      dialogDelete: false,
      errorAlert: false,
      errorText: '',
      datePickerMenu: false,
      backendServer: process.env.VUE_APP_BACKEND_SERVER
    }
  },

  methods: {
    initialize () {

      this.errorAlert = false

      this.editedItem = {
        num: null,
        dateOfIssue: new Date().toISOString().substr(0, 10),
        volumeNum: '',
        issueNum: '',
        attachment: []
      }

      axios.get(process.env.VUE_APP_BACKEND_SERVER + '/bulletin?type=board')
          .then((response) => {
            this.bulletinData = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })
    },

    deleteItem (num) {
      this.dialogDelete = true
      this.editedItem.num = num
    },

    deleteItemConfirm () {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        axios.create({
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        })
            .delete(process.env.VUE_APP_BACKEND_SERVER + '/bulletin/' + this.editedItem.num)
            .then((response) => {
              this.$store.commit('enableSnackbar', {
                text: response.data,
                timeout: 2000
              })
              this.initialize()
              this.closeDelete()
            })
            .catch((error) => {
              console.log(error.response)
            })
      }).catch((error) => {
        console.log(error)
      });
    },

    close () {
      this.dialog = false
      this.initialize()
    },

    closeDelete () {
      this.dialogDelete = false
    },

    async save () {
      this.errorAlert = false

      let fd = new FormData()

      fd.append('dateofissue', this.editedItem.dateOfIssue)
      fd.append('volumenum', this.editedItem.volumeNum)
      fd.append('issuenum', this.editedItem.issueNum)

      for (let i = 0; i < this.editedItem.attachment.length; i++) {
        fd.append('attachment', this.editedItem.attachment[i])
      }

      await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        axios.create({
          headers: {
            'Authorization': 'Bearer ' + idToken,
            'Content-Type': 'multipart/form-data'
          }
        })
            .post(process.env.VUE_APP_BACKEND_SERVER + '/bulletin', fd)
            .then((response) => {
              this.$store.commit('enableSnackbar', {
                text: response.data,
                timeout: 2000
              })
              this.initialize()
              this.close()
            })
            .catch((error) => {
              if (error.response) {
                this.errorAlert = true
                this.errorText = error.response.data
              }
              else if (error.request) {
                this.errorAlert = true
                this.errorText = "첨부 파일은 최대 10MB를 넘을 수 없습니다."
              }

            })
      }).catch((error) => {
        console.log(error)
      });
    }
  },

  computed: {
    bulletinWidth () {
      let width

      if (this.$vuetify.breakpoint.name == 'xs') {
        width = 350
      } else if (this.$vuetify.breakpoint.name == 'sm') {
        width = 550
      } else {
        width = 700
      }

      return width
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>