<template>
  <v-main app>
    <Subtitle>새가족 등록안내</Subtitle>

    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            새가족부
          </v-card-title>

          <v-divider class="mx-2"></v-divider>

          <v-row>
            <v-col cols="12" sm="7" class="d-flex child-flex">
              <v-card
                  elevation="0"
                  class="mx-2 mx-lg-5"
              >
                <v-card-text class="font-weight-bold text-subtitle-2">
                  처음 교회에 나오신 분이나 다른 교회에서 신앙생활을 하시다 이사 및 개인의 사정 등으로 문호공동체에서 함께 예배하며, 서로의 삶을 나누며, 그리스도의 선한 영향력을 끼치는 공동체의 일원으로 신앙생활을 하기 원하는 분을 등록부터 정착하기 까지 안내합니다.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="5" class="d-flex child-flex">
              <v-img
                  class="mx-2 mx-lg-5"
                  src="@/assets/family/main.png">
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>





    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            2024년 새가족 등록 교인
          </v-card-title>
          <v-divider class="mx-2"></v-divider>


          <v-alert
              icon="mdi-party-popper"
              text
              dense
              type="info"
              class="mx-2"
          >
            문호교회 가족됨을 환영합니다!
          </v-alert>

          <v-row no-gutters>
            <v-col cols="4"
                   sm="2"
                   md="1"
                   class="d-flex child-flex"
                   v-for="(newmember, index1) in newFamily2024"
                   :key="index1"
            >
              <v-img :src="newmember.image"
                     :lazy-src="newmember.image"
                     aspect-ratio="16/9"
                     class="grey lighten-2 ma-1 opacity"
              >
                <!-- <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template> -->
              </v-img>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>







    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            2023년 새가족 등록 교인
          </v-card-title>


          <v-row no-gutters>
            <v-col cols="4"
                   sm="2"
                   md="1"
                   class="d-flex child-flex"
                   v-for="(newmember, index2) in newFamily2023"
                   :key="index2"
            >
              <v-img :src="newmember.image"
                     :lazy-src="newmember.image"
                     aspect-ratio="16/9"
                     class="grey lighten-2 ma-1 opacity"
              >
                <!-- <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template> -->
              </v-img>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>





    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            2022년 새가족 등록 교인
          </v-card-title>


          <v-row no-gutters>
            <v-col cols="4"
                   sm="2"
                   md="1"
                   class="d-flex child-flex"
                   v-for="(newmember, index3) in newFamily2022"
                   :key="index3"
            >
              <v-img :src="newmember.image"
                     :lazy-src="newmember.image"
                     aspect-ratio="16/9"
                     class="grey lighten-2 ma-1 opacity"
              >
                <!-- <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template> -->
              </v-img>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>





    <v-row no-gutters justify="left" class="mt-2 d-flex flex-column flex-md-row">
      <v-col cols="12">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            2021년 새가족 등록 교인
          </v-card-title>


          <v-row no-gutters>
            <v-col cols="4"
                   sm="2"
                   md="1"
                   class="d-flex child-flex"
                   v-for="(newmember, index4) in newFamily2021"
                   :key="index4"
            >
              <v-img :src="newmember.image"
                     :lazy-src="newmember.image"
                     aspect-ratio="16/9"
                     class="grey lighten-2 ma-1 opacity"
              >
                <!-- <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template> -->
              </v-img>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>





    <v-row no-gutters justify="left" class="d-flex flex-column flex-md-row mt-10">

      <v-col cols="12" md="6">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5"
            tile
            flat
        >
          <v-card-title>
            새가족 등록 안내
          </v-card-title>

          <v-divider class="mx-2"></v-divider>

          <v-list>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="(item, i) in howto"
                  :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-card>
      </v-col>



      <v-col cols="12" md="6">
        <v-card
            elevation="0"
            class="mx-2 mx-lg-5 pb-2 mb-3"
        >
          <v-card-title>
            새가족 문의
          </v-card-title>
          <v-divider class="mx-2"></v-divider>

          <v-list>
            <v-list-item>
              <v-chip small class="mr-2">
                교회사무실
              </v-chip>
              <v-list-item-content>
                <v-list-item-title>
                  신동성 목사
                </v-list-item-title>
                <v-list-item-subtitle>
                  (031-772-1693)
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-chip small class="mr-2">
                새가족담당
              </v-chip>
              <v-list-item-content>
                <v-list-item-title>
                  김해연 목사
                </v-list-item-title>
                <v-list-item-subtitle>
                  (010-4111-3624)
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-chip  small class="mr-2">
                새가족부장
              </v-chip>
              <v-list-item-content>
                <v-list-item-title>
                  신현숙 권사
                </v-list-item-title>
                <v-list-item-subtitle>
                  (010-8968-6998)
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card>
      </v-col>

    </v-row>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Join",

  components: { Subtitle },

  data () {
    return {
      howto: [
        {
          icon: 'mdi-numeric-1-circle',
          text: '새가족 부원을 통해 등록 카드를 작성합니다.'
        },
        {
          icon: 'mdi-numeric-2-circle',
          text: '예배 순서 중 새가족을 환영합니다.'
        },
        {
          icon: 'mdi-numeric-3-circle',
          text: '예배 후 새가족실(샬롬홀)로 이동합니다.'
        },
        {
          icon: 'mdi-numeric-4-circle',
          text: '담임목사님과 사진촬영 및 만남과 기도의 시간을 갖습니다.'
        },
        {
          icon: 'mdi-numeric-5-circle',
          text: '교구와 구역을 연결해 줍니다.'
        },
        {
          icon: 'mdi-numeric-6-circle',
          text: '담당자와 새가족 4주 교육을 받습니다.'
        },
        {
          icon: 'mdi-numeric-7-circle',
          text: '새가족 등록 심방을 받습니다.'
        },
        {
          icon: 'mdi-numeric-8-circle',
          text: '분기별로 새가족 환영회를 갖습니다.'
        }
      ],

      newFamily2021: [
        { 'image': require('@/assets/family/2021/2021-02.jpg') },
        { 'image': require('@/assets/family/2021/2021-03.jpg') },
        { 'image': require('@/assets/family/2021/2021-04.jpg') },
        { 'image': require('@/assets/family/2021/2021-05.jpg') },
        { 'image': require('@/assets/family/2021/2021-06.jpg') },
        { 'image': require('@/assets/family/2021/2021-07.jpg') },
        { 'image': require('@/assets/family/2021/2021-08.jpg') },
        { 'image': require('@/assets/family/2021/2021-09.jpg') },
      ],
      newFamily2022: [
        { 'image': require('@/assets/family/2022/2022-01.jpg') },
        { 'image': require('@/assets/family/2022/2022-02.jpg') },
        { 'image': require('@/assets/family/2022/2022-03.jpg') },
        { 'image': require('@/assets/family/2022/2022-04.jpg') },
        { 'image': require('@/assets/family/2022/2022-05.jpg') },
        { 'image': require('@/assets/family/2022/2022-06.jpg') },
        { 'image': require('@/assets/family/2022/2022-07.jpg') },
        { 'image': require('@/assets/family/2022/2022-08.jpg') },
        { 'image': require('@/assets/family/2022/2022-09.jpg') },
        { 'image': require('@/assets/family/2022/2022-10.jpg') },
        { 'image': require('@/assets/family/2022/2022-11.jpg') },
        { 'image': require('@/assets/family/2022/2022-12.jpg') },
        { 'image': require('@/assets/family/2022/2022-13.jpg') },
        { 'image': require('@/assets/family/2022/2022-14.jpg') },
        { 'image': require('@/assets/family/2022/2022-15.jpg') },
        { 'image': require('@/assets/family/2022/2022-16.jpg') },
        { 'image': require('@/assets/family/2022/2022-17.jpg') },
        { 'image': require('@/assets/family/2022/2022-18.jpg') },
        { 'image': require('@/assets/family/2022/2022-19.jpg') },
        { 'image': require('@/assets/family/2022/2022-20.jpg') },
        { 'image': require('@/assets/family/2022/2022-21.jpg') },
        { 'image': require('@/assets/family/2022/2022-22.jpg') },
        { 'image': require('@/assets/family/2022/2022-23.jpg') }
      ],
      newFamily2023: [
        { 'image': require('@/assets/family/2023/2023-01.jpg') },
        { 'image': require('@/assets/family/2023/2023-02.jpg') },
        { 'image': require('@/assets/family/2023/2023-03.jpg') },
        { 'image': require('@/assets/family/2023/2023-04.jpg') },
        { 'image': require('@/assets/family/2023/2023-05.jpg') },
        { 'image': require('@/assets/family/2023/2023-06.jpg') },
        { 'image': require('@/assets/family/2023/2023-07.jpg') },
        { 'image': require('@/assets/family/2023/2023-08.jpg') },
        { 'image': require('@/assets/family/2023/2023-09.jpg') },
        { 'image': require('@/assets/family/2023/2023-10.jpg') },
        { 'image': require('@/assets/family/2023/2023-11.jpg') },
        { 'image': require('@/assets/family/2023/2023-12.jpg') },
        { 'image': require('@/assets/family/2023/2023-13.jpg') },
        { 'image': require('@/assets/family/2023/2023-14.jpg') },
        { 'image': require('@/assets/family/2023/2023-15.jpg') },
        { 'image': require('@/assets/family/2023/2023-16.jpg') },
        { 'image': require('@/assets/family/2023/2023-17.jpg') },
        { 'image': require('@/assets/family/2023/2023-18.jpg') },
        { 'image': require('@/assets/family/2023/2023-19.jpg') },
        { 'image': require('@/assets/family/2023/2023-20.jpg') },
        { 'image': require('@/assets/family/2023/2023-21.jpg') },
        { 'image': require('@/assets/family/2023/2023-22.jpg') },
        { 'image': require('@/assets/family/2023/2023-23.jpg') },
        { 'image': require('@/assets/family/2023/2023-24.jpg') },
        { 'image': require('@/assets/family/2023/2023-25.jpg') },
        { 'image': require('@/assets/family/2023/2023-26.jpg') },
        { 'image': require('@/assets/family/2023/2023-27.jpg') },
        { 'image': require('@/assets/family/2023/2023-28.jpg') },
        { 'image': require('@/assets/family/2023/2023-29.jpg') },
        { 'image': require('@/assets/family/2023/2023-30.jpg') },
        { 'image': require('@/assets/family/2023/2023-31.jpg') },
        { 'image': require('@/assets/family/2023/2023-32.jpg') },
        { 'image': require('@/assets/family/2023/2023-33.jpg') },
        { 'image': require('@/assets/family/2023/2023-34.jpg') }
      ],
      newFamily2024: [
        { 'image': require('@/assets/family/2024/2024-01.jpg') },
        { 'image': require('@/assets/family/2024/2024-02.jpg') },
        { 'image': require('@/assets/family/2024/2024-03.jpg') },
        { 'image': require('@/assets/family/2024/2024-04.jpg') },
        { 'image': require('@/assets/family/2024/2024-05.jpg') }
      ]
    }
}
}
</script>

<style scoped>
.opacity {
  opacity: 0.7;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
  letter-spacing : -0.04em;
}
</style>