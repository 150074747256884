<template>
  <v-main app>

    <v-card
        tile
        elevation="0"
        height="100%"
        v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-img src="@/assets/background.jpg" cover height="100%">
        <v-carousel
            cycle
            hide-delimiter-background
            show-arrows-on-hover
            :show-arrows="false"
            height="450"
        >
          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  VISION
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-15">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  문호교회 비전
                </div>
                <div class="d-flex justify-center font-weight-bold text-h5 text-sm-h4 text-md-h3 text-xl-h2 mt-5">
                  깊은 영성으로 세상을 아름답게
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>


          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  VALUE
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-0">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  문호교회가 꿈꾸는 공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-xl-h6">
                  (7가지 핵심 가치)
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5 mt-4">
                  성령과 말씀이 충만한 예배공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  하나님의 사람을 세우는 양육공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  땅끝까지 복음을 전하는 선교공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  지역사회와 함께하는 섬김공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  행복하고 복음적인 가정공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  전인적인 회복이 있는 치유공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h6 text-xl-h5">
                  다음세대와 함께하는 비젼공동체
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>


          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  SLOGON
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-15">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  2024년 문호교회 표어
                </div>
                <div class="d-flex justify-center font-weight-bold text-h5 text-sm-h4 text-md-h3 text-xl-h2 mt-5">
                  더 깊이 복음 안으로, 더 멀리 세상 속으로
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>


        <v-row dense no-gutters class="d-flex flex-column justify-start flex-sm-row">
          <v-col
              cols="12"
              md="4"
          >
            <v-card
                tile
                elevation="0"
                class="my-2 ma-md-3 pa-3 px-sm-5 pa-md-7"
                v-bind:color="cardColorScreen"
            >
              <v-card-title class="headline">
                문호교회
              </v-card-title>

              <v-card-subtitle class="mt-1 text-subtitle-1">1905년 창립예배 이후, 지난 100년 동안 하나님께서 부어주신 축복과 은혜에 감사하며 새로운 100년의 영광을 준비하고 있습니다.</v-card-subtitle>

              <v-card-text class="mt-auto">
                <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                >
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/history')">발자취</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/people')">섬기는 사람들</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/map')">오시는 길</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/join')">등록안내</v-btn>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-card
                tile
                elevation="0"
                class="my-2 ma-md-3 pa-3 px-sm-5 pa-md-7"
                v-bind:color="cardColorScreen"
            >
              <v-card-title class="headline">
                예배안내
              </v-card-title>

              <v-card-subtitle class="mt-1 text-subtitle-1">문호교회는 하나님을 찬미하는 예배 공동체입니다.</v-card-subtitle>

              <v-card-text class="mt-auto">
                <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                >
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/live')">예배실황</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/sermon')">다시듣기</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/meetingtime')">예배 및 모임시간</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/offertory')">온라인헌금</v-btn>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>


          <v-col
              cols="12"
              md="4"
          >
            <v-card
                tile
                elevation="0"
                class="my-2 ma-md-3 pa-3 px-sm-5 pa-md-7"
                v-bind:color="cardColorScreen"
            >
              <v-card-title class="headline">
                교회소식
              </v-card-title>

              <v-card-subtitle class="mt-1 text-subtitle-1">문호교회의 주요 행사 및 일정을 안내합니다.</v-card-subtitle>

              <v-card-text>
                <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                >
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/bulletin')">주보</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/schedule')">행사 및 일정</v-btn>
                  <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/gallery')">문호 갤러리</v-btn>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-img>
    </v-card>





    <v-card
        tile
        elevation="0"
        height="100%"
        v-else
    >
      <v-carousel
          cycle
          hide-delimiter-background
          show-arrows-on-hover
          :show-arrows="false"
          height="450"
      >
        <v-img src="@/assets/background.jpg" cover height="100%">

          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  VISION
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-15">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  문호교회 비전
                </div>
                <div class="d-flex justify-center font-weight-bold text-h5 text-sm-h4 text-md-h3 text-xl-h2 mt-5">
                  깊은 영성으로 세상을 아름답게
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>



          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  VALUE
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-0">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  문호교회가 꿈꾸는 공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h6 text-md-h6 text-xl-h5">
                  (7가지 핵심가치)
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4 mt-4">
                  성령과 말씀이 충만한 예배공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  하나님의 사람을 세우는 양육공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  땅끝까지 복음을 전하는 선교공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  지역사회와 함께하는 섬김공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  행복하고 복음적인 가정공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  전인적인 회복이 있는 치유공동체
                </div>
                <div class="d-flex justify-center font-weight-bold text-h6 text-sm-h6 text-md-h5 text-xl-h4">
                  다음세대와 함께하는 비젼공동체
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>



          <v-carousel-item light>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4 text-md-h6">
                  SLOGON
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col align-self="center" class="mt-15">
                <div class="d-flex justify-center font-weight-bold text-subtitle-1 text-sm-h5 text-md-h4 text-xl-h3">
                  2024년 문호교회 표어
                </div>
                <div class="d-flex justify-center font-weight-bold text-h5 text-sm-h4 text-md-h3 text-xl-h2 mt-5">
                  더 깊이 복음 안으로, 더 멀리 세상 속으로
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-img>
      </v-carousel>




      <v-row dense no-gutters class="d-flex flex-column justify-start flex-sm-row">
        <v-col
            cols="12"
        >
          <v-card
              elevation="0"
              class="my-2 pa-3 px-sm-5 pa-md-7"
              v-bind:color="cardColorMobile"
          >
            <v-card-title class="headline">
              문호교회
            </v-card-title>

            <v-card-subtitle class="mt-1 text-subtitle-1">1905년 창립예배 이후, 지난 100년 동안 하나님께서 부어주신 축복과 은혜에 감사하며 새로운 100년의 영광을 준비하고 있습니다.</v-card-subtitle>

            <v-card-text class="mt-auto">
              <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
              >
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/history')">발자취</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/people')">섬기는 사람들</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/map')">오시는 길</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/join')">등록안내</v-btn>
              </v-chip-group>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col
            cols="12"
        >
          <v-card
              elevation="0"
              class="my-2 pa-3 px-sm-5 pa-md-7"
              v-bind:color="cardColorMobile"
          >
            <v-card-title class="headline">
              예배안내
            </v-card-title>

            <v-card-subtitle class="mt-1 text-subtitle-1">문호교회는 하나님을 찬미하는 예배 공동체입니다.</v-card-subtitle>

            <v-card-text class="mt-auto">
              <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
              >
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/live')">예배실황</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/sermon')">다시듣기</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/meetingtime')">예배 및 모임시간</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/offertory')">온라인헌금</v-btn>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>


        <v-col
            cols="12"
        >
          <v-card
              elevation="0"
              class="my-2 pa-3 px-sm-5 pa-md-7"
              v-bind:color="cardColorMobile"
          >
            <v-card-title class="headline">
              교회소식
            </v-card-title>

            <v-card-subtitle class="mt-1 text-subtitle-1">문호교회의 주요 행사 및 일정을 안내합니다.</v-card-subtitle>

            <v-card-text>
              <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
              >
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/bulletin')">주보</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/schedule')">행사 및 일정</v-btn>
                <v-btn outlined rounded class="mr-2 mt-2" v-on:click="movePage('/gallery')">문호 갤러리</v-btn>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-card>

  </v-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',

  computed: {
    cardColorScreen () {
      return this.$vuetify.theme.dark ? '#333333' : '#FEFEFE'
    },

    cardColorMobile () {
      return this.$vuetify.theme.dark ? '#333333' : '#ECEFF1'
    }
  },

  methods: {
    movePage (path) {
      this.$router.push({path: path})
    }
  }
}
</script>
