<template>
  <v-main app>
    <Subtitle>시설안내</Subtitle>

  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "Facility",

  components: { Subtitle }
}
</script>

<style scoped>

</style>